import parseUri from "@/utils/parseuri"
import windows from "@/core/windows"
import settings from "./settings"
import { CookieError } from "@/errors"

const doc = windows.site.document

export function getCookieDomain(optionalHost?: string) {
  const host = optionalHost || windows.site.location.host
  if (settings.subDomain && host.endsWith(settings.subDomain)) {
    return `.${settings.subDomain}`
  }
  if (settings.extraHosts) {
    for (let i = 0; i < settings.extraHosts.length; i += 1) {
      const eh = parseUri(settings.extraHosts[i]).hostname
      if (host.endsWith(eh) && host.length >= eh.length) {
        return `.${eh}`
      }
    }
  }
  return null
}

export function getCookieTime() {
  return settings.cookieTime === undefined ? 24 * 365 * 4 : settings.cookieTime
}

/**
 * Generates the permutation of the possible shadowing domains. Given a domain
 * app.mobi.example.com  -> [app.mobi.example.com, mobi.example.com, example.com ]
 * The shadowing domain clearing logic is required when transitioning from
 * www.domain.com to *.domain.com.
 * @stephen possibly remembers the details better. The problem with monitoring the
 * usage is that its like an error condition that happens rarely when changing
 * the settings but if the logic is removed, it will be ”broken” for someone
 * who has visited earlier.
 *
 * @param {String} domain
 * @return {Array<String>}
 */
function getShadowingDomains(domain: string) {
  const domains: (string | null)[] = []
  domains.push(null)
  const parts = domain.split(".")
  while (parts.length > 1) {
    const curDomain = parts.join(".")
    domains.push(`.${curDomain}`)
    parts.shift()
  }
  return domains
}

function clearShadowingCookies(
  name: string,
  value: string | null,
  domain: string | null,
  path: string,
  currentDomain: string
) {
  const shadowingDomains = getShadowingDomains(currentDomain)
  if (!domain) {
    // eslint-disable-next-line no-param-reassign
    domain = null
  } else if (domain.indexOf(".") !== 0) {
    // eslint-disable-next-line no-param-reassign
    domain = `.${domain}`
  }
  for (let i = 0; i < shadowingDomains.length; i += 1) {
    if (shadowingDomains[i] !== domain) {
      // eslint-disable-next-line no-use-before-define
      doCreate(name, null, null, shadowingDomains[i], path)
      // eslint-disable-next-line no-use-before-define
      if (readCookie(name) === value) {
        break
      }
    }
  }
}

function readCookie(name: string) {
  const nameEQ = `${name}=`
  const ca = doc.cookie.split(";")

  // eslint-disable-next-line no-restricted-syntax
  for (let c of ca) {
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}

/**
 * When code to set secure cookie, e.g document.cookie="name=value; secure"
 * executed on insecure page, then cookie is not set. To be able to track such
 * cases here added error reporting.
 *
 * @param {String} name the name cookie name to validate.
 * @param {Number} hours the TTL for cookie, used as flag to check if cookie created or deleted.
 */
function reportIfCookieMissing(name: string, hours: number) {
  if (hours < 1) return
  const allCookies = doc.cookie
  if (allCookies.indexOf(`${name}=`) === -1) {
    throw new CookieError(`Cookie ${name} not present!`)
  }
}

function doCreate(name: string, value: string | null, hours: number | null, domain: string | null, path: string) {
  const t: [string, unknown][] = []
  t.push([name, value])
  if (value === null) {
    // eslint-disable-next-line no-param-reassign
    hours = -1
  }
  if (hours) {
    // Safari requires toGMTString for cookies
    // http://forum.slicehost.com/index.php?p=/discussion/929/
    t.push(["expires", new Date(new Date().getTime() + hours * 60 * 60 * 1000).toUTCString()])
  }
  if (domain) {
    t.push(["domain", domain])
  }
  if (path) {
    t.push(["path", path])
  }
  t.push(["SameSite", "Lax"])
  let s = ""
  for (let i = 0; i < t.length; i += 1) {
    s += `${t[i].join("=")}; `
  }
  if (settings && settings.secureCookie) {
    s += "secure"
  }
  // eslint-disable-next-line no-param-reassign
  doc.cookie = s

  reportIfCookieMissing(name, hours!)
}

function createCookie(
  name: string,
  value: string | null,
  hours: number = getCookieTime(),
  domain: string | null = getCookieDomain(),
  path: string = "/"
) {
  doCreate(name, value, hours, domain, path)
  // Check for shadowing cookies
  if (readCookie(name) !== value && doc.location) {
    clearShadowingCookies(name, value, domain, path, doc.location.hostname)
  }
}

export default {
  get: readCookie,
  set: createCookie
}
