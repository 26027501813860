import * as v from "valibot"
import { createAssertFn, numberOrString, numberToString } from "@/utils/validator"
import { SearchHit, SearchTrackOptions } from "./types"
import { Equals, Expect } from "@/types"

const clickSchema = v.looseObject({
  productId: v.pipe(numberOrString, numberToString, v.string()),
  url: v.optional(v.string())
})

const typeSchema = v.picklist(["serp", "autocomplete", "category"])

export const assertValidClick = createAssertFn("SearchHit", clickSchema)

export const assertValidType = createAssertFn("SearchTrackOptions", typeSchema)

/** @hidden */
export type tests = [
  Expect<Equals<v.InferOutput<typeof clickSchema>, SearchHit>>,
  Expect<Equals<v.InferOutput<typeof typeSchema>, SearchTrackOptions>>
]
