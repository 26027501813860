import { ResponseData } from "../requests/fetchPopupData"
import { readPopupAttributes, writePopupAttributeWithPreview } from "../popupAttributes"
import { Props as BaseProps } from "../types"
import * as v from "valibot"

type Props = {
  popupId: string
  responseData: ResponseData
  preview: BaseProps["preview"]
}

const responseDataSchema = v.object({
  coupon: v.optional(v.string())
})

// check response for a coupon code & update coupon cached in cookie
export function extractAndCacheCouponCode({ popupId, responseData, preview }: Props) {
  const couponCode = readPopupAttributes(popupId).coupon
  if (!couponCode || !responseData.data) {
    return
  }

  try {
    const data = v.parse(responseDataSchema, responseData.data)
    if (data && data.coupon && couponCode !== data.coupon) {
      writePopupAttributeWithPreview(popupId, "coupon", preview, data.coupon)
    }
  } catch (error) {
    // Ignore
  }
}
