import windows from "@/core/windows"

function executeCommandCopy(text: string) {
  const doc = windows.site.document
  const textarea = document.createElement("textarea")
  textarea.value = text
  textarea.style.position = "fixed"
  textarea.style.opacity = "0"
  doc.body.appendChild(textarea)
  textarea.focus()
  textarea.select()
  try {
    doc.execCommand("copy")
  } finally {
    doc.body.removeChild(textarea)
  }
}

async function asyncAPICopy(text: string) {
  await navigator.clipboard.writeText(text)
}

export function copyToClipboard(button: HTMLElement, source: () => HTMLElement) {
  return new Promise<void>((resolve, reject) => {
    button.addEventListener("click", async () => {
      try {
        const copyFn = navigator.clipboard ? asyncAPICopy : executeCommandCopy
        await copyFn(source().textContent!)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  })
}
