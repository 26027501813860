import page from "@/core/page"
import logger from "@/core/logger"
import updateRating from "./rating"
import { parseJSON } from "@/utils/json"
import { Product } from "@/core/tagging/types"

interface Ratings {
  bestRating: string | number
  ratingValue: string | number
  reviewCount: string
  ratingCount: string
}

function hasAggregateRating(o: unknown): o is { aggregateRating: Ratings } {
  if (o && typeof o === "object") {
    const context = "@context" in o && o["@context"]
    const type = "@type" in o && o["@type"]
    const aggregateRating = "aggregateRating" in o && o["aggregateRating"]
    return (
      (context === "http://schema.org/" || context === "http://schema.org") && type === "Product" && !!aggregateRating
    )
  }
  return false
}

/**
 * Parses the embedded JSON-LD data in the page and updates the ratings and
 * reviews of the product.
 *
 * @param {Product} object the product object to be updated
 * @returns a boolean indicating whether the object was updated or not
 */
export default function parseAggregateRatings(object: Product) {
  const jsonLds = page.selectAll<HTMLScriptElement>('script[type="application/ld+json"]')

  // Map each of the selected JSON-LD script elements into JSON objects
  const objects = Array.from(jsonLds)
    .map(jsonLd => {
      const scriptText = jsonLd.text.trim().replace(/(\r\n|\n|\r)/gm, "")
      if (scriptText.length) {
        try {
          return parseJSON(scriptText)
        } catch (e) {
          logger.warn("Your json/ld content is malformed", e, { local: true })
        }
      }
    })
    .filter(Boolean)
    .flatMap(o => (Array.isArray(o) ? o : [o]))

  // Filter out all objects that aren't http://schema.org/Product objects
  // noinspection JSUnresolvedVariable
  const product = objects.find(hasAggregateRating)

  // Extract the first product and update the product object with the values
  if (product) {
    const { aggregateRating } = product
    if (aggregateRating && typeof aggregateRating === "object") {
      const { bestRating, ratingValue, reviewCount, ratingCount } = aggregateRating
      updateRating(object, bestRating, ratingValue, reviewCount, ratingCount)
      return true
    }
  }

  return false
}
