import { LOCAL_STORAGE_KEY, isPopupId, lookupPopupId } from "./utils"
import { PopupAttributes } from "./types"
import { localStore } from "@/core/store"

export function readPopupAttributes(): Record<string, PopupAttributes>
export function readPopupAttributes(popupId: string): PopupAttributes
export function readPopupAttributes(popupId?: string) {
  const parsed: Record<string, unknown> | undefined = localStore.getAsJson(LOCAL_STORAGE_KEY)
  if (!parsed) {
    return {}
  }
  // Support campaign name input for now - used for event response messsage's campaignId "cdc"
  if (popupId && !isPopupId(popupId)) {
    popupId = lookupPopupId(popupId)
  }
  if (popupId && parsed[popupId]) {
    return parsed[popupId]
  }
  return parsed
}
