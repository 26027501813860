import { Event, isEventRefType, isEventType } from "@/core/event"
import * as v from "valibot"
import { createValidateFn, numberOrString, numberToString } from "@/utils/validator"
import { Equals, Expect, Widen } from "@/types"

type Widened<T> = { [K in keyof T]: Widen<T[K]> }

const EventSchema = v.object({
  type: v.pipe(v.string(), v.check(isEventType, "Value supplied is invalid")),
  target: v.optional(v.pipe(numberOrString, numberToString, v.string())),
  ref: v.optional(v.string()),
  refSrc: v.optional(v.string()),
  targetFragment: v.optional(v.pipe(numberOrString, numberToString, v.string())),
  refType: v.optional(v.pipe(v.string(), v.check(isEventRefType, "Invalid refType")))
})

export const validateEvent = createValidateFn("Event", EventSchema)

/** @hidden */
export type tests = [
  // Event and schema input are equal
  Expect<Equals<Widened<Event>, v.InferOutput<typeof EventSchema>>>
]
