import OpenChain, { Listener } from "./OpenChain"

export default class ExitIntent extends OpenChain {
  constructor(win: Window) {
    super(win)
  }

  clearTimeoutThenCall(listener: Listener) {
    for (let i = 0; i < this.listeners.length; i += 1) {
      if (this.listeners[i] !== listener) {
        continue
      }
      this.listeners[i].cb()
      this.deregister(i)
    }
  }

  handleMouseLeave(listener: Listener, mouseEvent: MouseEvent) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    if (listener.reEnterTimeout) {
      return
    }
    if (listener.okToOpen && !listener.okToOpen()) {
      return
    }

    // Check that mouse is on the top of the browser
    // This will rule out the scrollbar, debug toolbars on bottom and in
    // general if the mouse leaves the screen from down, left / right.
    if (mouseEvent && mouseEvent.y > 1) {
      return
    }

    if (mouseEvent && mouseEvent.stopPropagation) {
      mouseEvent.stopPropagation()
    }

    const reEntryTolerance = listener.config.reEntryTolerance || 0

    listener.reEnterTimeout = this.win.setTimeout(() => {
      self.clearTimeoutThenCall(listener)
    }, reEntryTolerance)
  }

  handleMouseEnter(listener: Listener) {
    if (listener.reEnterTimeout) {
      this.win.clearTimeout(listener.reEnterTimeout)
      listener.reEnterTimeout = undefined
    }
  }

  setup(listener: Listener) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const handleMouseEnterClos = () => {
      self.handleMouseEnter(listener)
    }
    const handleMouseLeaveClos = (event: MouseEvent) => {
      self.handleMouseLeave(listener, event)
    }
    if (this.win.document.documentElement.addEventListener) {
      this.win.document.documentElement.addEventListener("mouseleave", handleMouseLeaveClos, false)
      this.win.document.documentElement.addEventListener("mouseenter", handleMouseEnterClos, false)
    }
  }
}
