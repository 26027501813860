import { PropsWithModal } from "../types"
import { closeDialog } from "./closeDialog"

/**
 * Minimizes pop-up dialog to a ribbon
 */
export function hideDialog(props: PropsWithModal) {
  const { dialog, modal } = props
  const ribbon = dialog.querySelector<HTMLElement>("#NostoRibbon")
  if (ribbon) {
    modal.hide()
    ribbon.style.display = ""
  } else {
    closeDialog(props)
  }
}
