import parseUri, { ParseUriResult } from "@/utils/parseuri"
import domReadyCreator from "domready"
import { cleanParsedUrl } from "@/utils/url"
import settings from "@/core/settings"
import createLoader from "./loaders"
import windows from "./windows"
import { localStore } from "./store"
import mode, { setSiteUrl, Mode } from "./mode"
import { InitOptions, nostojs } from "./nostojs"

function windowTools(win: Window, scriptLoaderWindow: Window) {
  const { loadScript, loadOnce, xdr } = createLoader(() => scriptLoaderWindow)
  return {
    window: win,
    loadScript,
    loadOnce,
    xdr,
    domReady: domReadyCreator(win)
  }
}

type WindowTools = ReturnType<typeof windowTools>

/**
 * @hidden
 */
export interface Context {
  namespace: string
  created: Date
  domHasLoaded: boolean
  loader: nostojs
  initOptions?: InitOptions
  updateSiteUrl: () => void
  siteUrl: ParseUriResult
  siteUrlCleaned: string
  requests: {
    sent: unknown[]
    received: unknown[]
  }
  referer?: ParseUriResult
  site: WindowTools
  nosto: WindowTools
  debugToken: string
  mode: Mode
  popupShown: boolean
}

// @ts-expect-error fix context building
const context: Context = {}
export default context

/**
 * @param namespace used for testing
 */
export function createContext(): Context {
  if (Object.keys(context).length) {
    Object.keys(context).forEach(key => delete context[key])
  }
  context.namespace = "nosto"
  context.created = new Date()
  context.domHasLoaded = false

  if (windows.site.nostojs) {
    if (typeof windows.site.nostojs !== "function") {
      console.warn("window.nostojs already set as something other than a function")
    }
    context.loader = windows.site.nostojs
    context.initOptions = context.loader.o
  }
  if (!context.initOptions) {
    context.initOptions = {}
  }

  let locationHref: string
  // updateSiteUrl can be used to update the site url based on history based site navigation
  context.updateSiteUrl = () => {
    if (windows.site.document.location.href !== locationHref) {
      context.siteUrl = parseUri(windows.site.document.location.href)
      context.siteUrlCleaned = cleanParsedUrl(context.siteUrl, settings.nostoRefParam)!
      locationHref = windows.site.document.location.href
    }
  }
  context.updateSiteUrl()

  context.requests = {
    sent: [],
    received: []
  }
  if (windows.site.document.referrer) {
    context.referer = parseUri(windows.site.document.referrer)
  }
  context.site = windowTools(windows.site, windows.nosto)
  context.nosto = windowTools(windows.nosto, windows.nosto)

  setSiteUrl(context.siteUrl)
  context.mode = mode
  const debugVersion = context.siteUrl.searchParams.get("nostodebug") || localStore.get("nosto:dev")
  const hexShaRegEx = /^([a-f0-9]{40})$/
  if (debugVersion && hexShaRegEx.test(debugVersion)) {
    context.debugToken = debugVersion
  }

  windows.nosto._targetWindow = windows.site
  // @ts-expect-error unknown field
  windows.site[context.namespace] = windows.nosto
  return context
}
