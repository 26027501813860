import { validateEmail } from "../utils/utils"
import { getNostoAddress } from "../utils/dom"
import { pageTagging as extractTagging } from "@/core/tagging"

type Props = {
  dialog: HTMLElement
}

export function setupCouponButtons({ dialog }: Props) {
  const nostoAddress = getNostoAddress(dialog)
  if (!nostoAddress) {
    return
  }

  const nostoCouponGetBtn = dialog.querySelectorAll("#nostoOverlaySend, #nostoCouponGetBtn")
  const tagging = extractTagging()
  if (tagging?.customer?.email) {
    nostoAddress.value = tagging.customer.email
    if (!validateEmail(tagging.customer.email)) {
      nostoCouponGetBtn.forEach(btn => btn.classList.add("disabled"))
    }
  } else {
    nostoCouponGetBtn.forEach(btn => btn.classList.add("disabled"))
  }

  const elements = ["keyup", "change", "input", "paste"]
  elements.forEach(event => {
    nostoAddress.addEventListener(event, () => toggleCouponGetBtnClass(dialog, Array.from(nostoCouponGetBtn)))
  })
}

function toggleCouponGetBtnClass(dialog: HTMLElement, nostoCouponGetBtn: Element[]) {
  const nostoAddress = getNostoAddress(dialog)
  if (!nostoAddress) {
    return
  }
  const emailInput = nostoAddress.value
  nostoCouponGetBtn.forEach(btn => {
    // Value is not immediately present in the nostoAddress when paste
    // callback handler is being called. Therefore we get it a bit later
    setTimeout(() => {
      btn.classList.toggle("disabled", !validateEmail(emailInput))
    }, 1)
  })
}
