import currencies from "./currencies"
import * as v from "valibot"
import { createAssertFn, createValidateFn, nullish, numberOrString } from "@/utils/validator"
import { CartItem, ConversionItem, Equals, Expect, Extends, isPageType, PushedCustomer } from "@/types"
import { TaggingData, Product } from "@/core/tagging/types"

const stringToNumber = v.transform(
  (v: string | number) => (typeof v === "string" && !Number.isNaN(+v) ? Number(v) : v) as number
)
const currencyCheck = v.check((val: string) => currencies.includes(val), "Invalid currency code")

const cartItemSchema = v.object({
  name: v.string(),
  price_currency_code: v.pipe(v.string(), currencyCheck),
  product_id: v.string(),
  quantity: v.pipe(v.number(), v.minValue(0)),
  sku_id: v.optional(v.string()),
  unit_price: v.pipe(numberOrString, stringToNumber, v.number())
})

const orderItemSchema = v.object({
  name: v.string(),
  price_currency_code: v.pipe(v.string(), currencyCheck),
  product_id: v.string(),
  quantity: v.optional(v.pipe(v.number(), v.minValue(0))),
  sku_id: v.optional(v.string()),
  unit_price: v.optional(v.pipe(numberOrString, stringToNumber, v.number()))
})

const customerSchema = v.object({
  first_name: nullish(v.pipe(v.string(), v.minLength(1))),
  last_name: nullish(v.pipe(v.string(), v.minLength(1))),
  email: nullish(v.pipe(v.string(), v.email())),
  customer_reference: nullish(v.pipe(v.string(), v.minLength(1))),
  newsletter: nullish(v.union([v.literal("true"), v.literal("false"), v.boolean()]))
})

const productSchema = v.object({
  product_id: v.string(),
  selected_sku_id: nullish(v.string())
})

const DataSchema = v.object({
  customer: nullish(customerSchema),
  cart: nullish(
    v.object({
      // support for empty cart definitions
      items: v.optional(v.array(cartItemSchema))
    })
  ),
  order: nullish(
    v.object({
      info: v.optional(
        v.object({
          order_number: v.string()
        })
      ),
      items: v.array(orderItemSchema)
    })
  ),
  products: nullish(v.array(productSchema)),
  searchTerms: nullish(v.array(v.string())),
  categories: nullish(v.array(v.string())),
  tags: nullish(v.array(v.string())),
  elements: nullish(v.array(v.string())),
  pageType: nullish(v.pipe(v.string(), v.check(isPageType, "Invalid page type")))
})

export const assertValid = createAssertFn("Tagging", DataSchema)

export const validate = createValidateFn("Tagging", DataSchema)

/** @hidden */
export type tests = [
  // CartItem is equal to schema input
  Expect<Equals<CartItem, v.InferOutput<typeof cartItemSchema>>>,
  // ConversionItem is equal to schema input
  Expect<Equals<ConversionItem, v.InferOutput<typeof orderItemSchema>>>,
  // PushedCustomer is assignable to schema input
  Expect<Extends<PushedCustomer, v.InferOutput<typeof customerSchema>>>,
  // Product is assingable to schema input
  Expect<Extends<Product, v.InferOutput<typeof productSchema>>>,
  // Data is assignable to schema input
  Expect<Extends<TaggingData, v.InferOutput<typeof DataSchema>>>
]
