import OpenChain, { Listener } from "./OpenChain"

export default class AfterDelayLink extends OpenChain {
  constructor(win: Window) {
    super(win)
  }

  setup(listener: Listener) {
    this.win.setTimeout(listener.cb, listener.config.delay || 0)
  }
}
