import type { Props, ReqData } from "../types"
import settings from "@/core/settings"
import ctx from "@/core/context"
import { reqDataParams } from "../utils/utils"
import { clearPopupAttributes } from "../popupAttributes"

export type ResponseData = {
  campaign_id: string
  neverShowInSession: boolean
  effect: {
    fadein_min: number
    opacity_min: number
  }
  html: string
  data: string
}

export async function fetchPopupData(
  props: Props & {
    reqData: ReqData
  }
) {
  const { popupId, reqData } = props

  if (!popupId) {
    return
  }
  const actionUrl = "/overlay/discount-modal/show"

  let responseData: ResponseData | null = null
  try {
    const res = await fetch(`${settings.server}${actionUrl}?${reqDataParams(reqData)}`, {
      method: "GET",
      cache: "no-cache",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    })
    responseData = await res.json()
    return responseData
  } catch (error) {
    console.error(error)
    // clean cookie if present
    clearPopupAttributes(popupId)
    ctx.popupShown = false
  }

  return responseData
}
