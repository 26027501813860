import { SearchQuery } from "./types"

type UnflattenedFields = { [key: string]: UnflattenedFields | boolean }

/**
 * Converts { customFields: { key: true, value: true } name: true } to:
 * "customFields { key value }" name
 */
function generateGraphQLFields(obj: UnflattenedFields): string {
  const reduce = (o: UnflattenedFields, accumulator: string, nested = true): string => {
    const entries = Object.entries(o)
    return entries.reduce<string>((acc, [key, value], index) => {
      const isLast = index + 1 === entries.length
      return typeof value === "boolean"
        ? `${acc} ${key}${isLast && nested ? " }" : ""}`
        : `${reduce(value, `${acc} ${key} {`)}${isLast && nested ? " }" : ""}`
    }, accumulator)
  }
  return reduce(obj, "", false).trim()
}

/**
 * Converts ["customFields.key", "customFields.value", "name"] to:
 * { customField: { key: true, value: true } name: true }
 */
function unflattenFields(productFields: string[]) {
  return productFields.reduce<UnflattenedFields>((acc, field) => {
    const reduce = (parts: string[], accumulator: UnflattenedFields): UnflattenedFields => {
      if (parts.length > 1) {
        return {
          ...accumulator,
          [parts[0]]: reduce(parts.slice(1), accumulator[parts[0]] as UnflattenedFields)
        }
      }
      return {
        ...accumulator,
        [parts[0]]: true
      }
    }
    return reduce(field.split("."), acc)
  }, {})
}

function generateSelectedFields(fields: string[], mandatoryFields: string[]) {
  const selectedFields = Array.isArray(fields) ? fields : []
  const uniqueFields = mandatoryFields.concat(selectedFields)
  const uniqueSelectedFields = uniqueFields.filter((value, index, array) => array.indexOf(value) === index)
  return generateGraphQLFields(unflattenFields(uniqueSelectedFields))
}

const productTermsFacet = `
    ... on SearchTermsFacet {
      id
      field
      type
      name
      data {
          value
          count
          selected
      }
    }
  `
const productStatsFacet = `
    ... on SearchStatsFacet {
      id
      field
      type
      name
      min
      max
    }
  `
const keywordTermsFacet = `
    ... on SearchTermsFacet {
      field
      data {
          value
          count
      }
    }
  `

function buildQueryProducts(query: SearchQuery) {
  const hasProducts = "products" in query
  const hasProductFacets = query.products && Array.isArray(query.products.facets) && query.products.facets.length > 0
  return hasProducts
    ? `products {
            hits {
              ${generateSelectedFields(
                query.products && Array.isArray(query.products.fields) ? query.products.fields : [],
                ["productId"]
              )}
            }
            total
            size
            from
            ${
              hasProductFacets
                ? `facets {
                      ${productTermsFacet}
                      ${productStatsFacet}
                  }`
                : ""
            }
            collapse
            fuzzy
            categoryId
            categoryPath
          }
        `
    : ""
}

function buildQueryKeywords(query: SearchQuery) {
  const hasKeywords = "keywords" in query
  const hasKeywordFacets = query.keywords && Array.isArray(query.keywords.facets) && query.keywords.facets.length > 0
  return hasKeywords
    ? `keywords {
            hits {
              ${generateSelectedFields(
                query.keywords && Array.isArray(query.keywords.fields) ? query.keywords.fields : [],
                ["keyword"]
              )}
            ${
              hasKeywordFacets
                ? `
                  facets {
                      ${keywordTermsFacet}
                  }
                `
                : ""
            }
          } 
          total
          size
        }
        `
    : ""
}

function buildQueryAbTests() {
  return `abTests {
      id
    activeVariation {
       id
    }
  }`
}

export function buildSearchQuery(query: SearchQuery) {
  return `
    query (
        $abTests: [InputSearchABTest!],
        $accountId: String,
        $query: String,
        $segments: [String!],
        $rules: [String!],
        $products: InputSearchProducts,
        $keywords: InputSearchKeywords,
        $sessionParams: InputSearchQuery
      ) {
          search(
            accountId: $accountId
            query: $query
            segments: $segments
            rules: $rules
            products: $products
            keywords: $keywords
            sessionParams: $sessionParams
            abTests: $abTests
          ) {
              query
              redirect
              ${buildQueryProducts(query)}
              ${buildQueryKeywords(query)}
              ${buildQueryAbTests()}
            }
        }
    `
    .trim()
    .replace(/\s+/g, " ")
}
