import page from "@/core/page"
import windows from "@/core/windows"
import { asyncPlacementMode, Queue } from "./placementQueue"
import subscribeIntersectionObserver from "./intersection"
import { DynamicPlacementDTO } from "@/types"

type Placement = Pick<DynamicPlacementDTO, "cssSelector" | "mutation" | "intersection">

const hasMutationObserver = "MutationObserver" in window

const queue: Queue<Placement, MutationObserver> = new Queue(() => {
  const observer = new MutationObserver(() =>
    queue.update(c => page.select(c.cssSelector!)!, subscribeIntersectionObserver)
  )
  observer.observe(windows.site.document, {
    subtree: true,
    childList: true,
    attributes: true,
    attributeFilter: ["class"]
  })
  return observer
})

export default function subscribeMutationObserver(divId: string, configuration: Placement) {
  // do not perform async placement management if no handler is defined in placementQueue
  if (!configuration.mutation || !hasMutationObserver || !asyncPlacementMode()) {
    return
  }
  queue.queue(divId, configuration)
}
