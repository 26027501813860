import { getDebugRequest } from "@/core/debug"
import settings from "@/core/settings"
import context from "@/core/context"
import { findOrder } from "@/core/tagging"
import mobile from "@/utils/mobile"
import evaluateFilters from "@/core/filters/filters"
import { PopupCart, Condition, PopupEffect, ResponseData } from "./types"
import { FilterRule, DebugRequestParamsDTO } from "@/types"
import { baseDiscountPopup, readPopupAttributes, writePopupAttribute, reportPopupAnalytics } from "@/core/popups"
import { PopupTrigger } from "@/core/popups/types"

type ShowPopupOptions = {
  campaignId?: string
  popupId?: string
  effect: Partial<PopupEffect>
  trigger: PopupTrigger
  preview?: boolean
  cart?: PopupCart
}

export interface PopupAttributes {
  coupon?: string
  state?: string
  campaignId?: string
  checkout?: boolean
}

type ArrayField = "categories" | "tags" | "brands" | "page_types" | "urls" | "referer_urls" | "url_parameters"

// @params object Additional parameters
// @context object Nosto context object (see recoveryFeature.js for examples on how the context is used)
function discountPopup() {
  // Preview the popup
  function preview(popupId: string, campaignId: string, effect?: PopupEffect) {
    campaignId = campaignId || ""
    // NOTE: preview is for previewing the look not the configuration so need to use campaignId
    internal.showPopup({
      popupId,
      campaignId,
      effect: effect || {},
      trigger: "api",
      preview: true
    })
  }

  function previewById(popupId: string, effect: PopupEffect) {
    internal.showPopup({
      popupId,
      effect: effect || {},
      trigger: "api",
      preview: true
    })
  }

  function done(popupId: string) {
    writePopupAttribute(popupId, "coupon", null)
    writePopupAttribute(popupId, "state", "closed")
  }

  function forAllCampaigns(
    cookieCheckF: (popupAttributes: PopupAttributes) => boolean,
    campaignF: (popupId: string, effect: PopupEffect, condition: Condition, triggerType: PopupTrigger) => void
  ) {
    let i
    let effect
    let condition
    let triggerType
    let popupId
    const popupAttributes = readPopupAttributes() || {}
    for (popupId in popupAttributes) {
      if (!popupAttributes.hasOwnProperty(popupId)) {
        continue
      }
      if (cookieCheckF(popupAttributes[popupId])) {
        for (triggerType in settings.discountPopupTriggers) {
          if (!settings.discountPopupTriggers.hasOwnProperty(triggerType)) {
            continue
          }
          for (i = 0; i < settings.discountPopupTriggers[triggerType].length; i += 1) {
            if (settings.discountPopupTriggers[triggerType][i].popup_id === popupId) {
              effect = settings.discountPopupTriggers[triggerType][i].effect
              condition = settings.discountPopupTriggers[triggerType][i].condition
              if (settings.discountPopupTriggers[triggerType][i].enabled !== false) {
                // @ts-expect-error type mismatch
                campaignF(popupId, effect, condition, triggerType)
              }
              return
            }
          }
        }
      }
    }
  }

  function stampOnCheckoutPage() {
    const onCheckout = (cookieVal: PopupAttributes) => !!cookieVal.checkout
    const stamp = (popupId: string) => {
      const { campaignId } = readPopupAttributes(popupId)
      reportPopupAnalytics(campaignId!, "checkoutredirect")
      writePopupAttribute(popupId, "campaignId")
      writePopupAttribute(popupId, "checkout", false)
    }
    forAllCampaigns(onCheckout, stamp)
  }

  function openMinimized() {
    let show
    let orderSeen = false

    function isShown(popupAttribute: PopupAttributes) {
      return popupAttribute.state === "shown"
    }

    function checkRibbonSpecificConditions(conditions: Condition) {
      const simplifiedConditions: Condition = {
        hide_on_desktop: conditions.hide_on_desktop,
        hide_on_mobile: conditions.hide_on_mobile
      }
      if (conditions.treat_url_conditions_as_filters) {
        simplifiedConditions["urls"] = conditions.urls
        simplifiedConditions["exc_urls"] = conditions.exc_urls
      }
      return evaluateCondition(simplifiedConditions, {})
    }

    // If we see order tagging, assume that order has been made
    // and coupon isn't to be shown.
    if (findOrder()) {
      orderSeen = true
    }
    // eslint-disable-next-line prefer-const
    show = (popupId: string, effect: PopupEffect, condition: Condition, triggerType: PopupTrigger) => {
      if (settings.popupRibbonUrlFilter) {
        if (!checkRibbonSpecificConditions(condition)) {
          return false
        }
      } else {
        if (condition && ((condition.hide_on_desktop && !mobile) || (condition.hide_on_mobile && mobile))) {
          return false
        }
      }
      if (orderSeen) {
        done(popupId)
      } else {
        if (effect) {
          effect.fadein_min = 0
        }
        internal.showPopup({
          popupId,
          effect,
          trigger: triggerType
        })
      }
      return true
    }
    forAllCampaigns(isShown, show)
  }

  function open(popupId: string, response: ResponseData | null, effect: PopupEffect, trigger: PopupTrigger) {
    const cart: PopupCart = {
      total: 0,
      size: 0
    }
    if (response && response.ct) {
      cart.total = response.ct
    }
    if (response && response.cs) {
      cart.size = response.cs
    }
    internal.showPopup({
      popupId,
      cart,
      effect,
      trigger
    })
  }
  function notEmpty(arr: unknown[] | undefined) {
    return arr && arr.length > 0
  }

  // True if arrays are not empty and arr1 begins with or equals to arr2
  function startsWithArray<T>(arr1: T[], arr2: T[]) {
    for (let i = 0; i < arr2.length; i += 1) {
      if (arr1.length - 1 < i || arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }

  function startsWithAnyArray<T>(array: T[], arrays: T[][]) {
    if (!notEmpty(array)) {
      return false
    }
    for (let i = 0; i < arrays.length; i += 1) {
      if (!notEmpty(arrays[i])) {
        continue
      }
      if (startsWithArray(array, arrays[i])) {
        return true
      }
    }
    return false
  }

  function evaluateCondition(c: Condition, response: ResponseData) {
    // handle enabled flags
    if (c.enabledInJs === false || (c.enabled === false && !c.enabledInJs)) {
      return false
    }
    // evaluate conditions
    let result = true
    if (!settings.discountPopupVisible) {
      result = false
    }
    if ((c.hide_on_desktop && !mobile) || (c.hide_on_mobile && mobile)) {
      result = false
    }

    function add(field: string, cKey: ArrayField, filters: FilterRule[], negate?: boolean) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      c[cKey] && c[cKey]!.length && filters.push({ field, operator: "INCLUDES", values: c[cKey]!, negate })
    }

    const filterFields = [
      "categories",
      "tags",
      "brands",
      "page_types",
      "urls",
      "referer_urls",
      "url_parameters"
    ] as const
    const filterDef: FilterRule[] = []
    filterFields.forEach(key => {
      add(key, key, filterDef)
    })
    filterFields.forEach(key => {
      add(key, `exc_${key}` as ArrayField, filterDef, true)
    })

    if (!evaluateFilters(filterDef)) {
      return false
    }

    if (c.min_cart_value && (!response.ct || response.ct < c.min_cart_value)) {
      result = false
    }
    if (c.min_cart_size && (!response.cs || response.cs < c.min_cart_size)) {
      result = false
    }
    if (c.max_cart_value && response.ct! > c.max_cart_value) {
      result = false
    }
    if (c.max_cart_size && response.cs! > c.max_cart_size) {
      result = false
    }
    if (c.min_page_views && response.pv! < c.min_page_views) {
      result = false
    }
    if (c.max_page_views && response.pv! > c.max_page_views) {
      result = false
    }
    if (notEmpty(c.locations) && !startsWithAnyArray(response.gl!, c.locations!)) {
      result = false
    }
    if (notEmpty(c.exc_locations) && startsWithAnyArray(response.gl!, c.exc_locations!)) {
      result = false
    }

    return result
  }

  function okToOpen(popupId: string, condition: Condition, responseData?: ResponseData) {
    return !openCheck(popupId, condition, responseData)
  }

  // Return null if OK to open, otherwise return an error message
  function openCheck(popupId: string, condition?: Condition, responseData?: ResponseData) {
    if (context.popupShown) {
      return `Another pop-up is being shown, popupId: ${context.popupShown}`
    }
    if (readPopupAttributes(popupId).state === "closed") {
      // don't show pop-up because it's already been closed by a customer
      return "The pop-up has been dismissed by the customer."
    }
    if (condition && responseData && !evaluateCondition(condition, responseData)) {
      return "The advanced rulesets did not match."
    }
    return null
  }

  let actions: ReturnType<typeof baseDiscountPopup>
  const internal = {
    // @showPopupOptions - Additional params that should be passed to public module script.
    showPopup(showPopupOptions: ShowPopupOptions) {
      let effect
      if (showPopupOptions.effect) {
        effect = {
          opacity_min: showPopupOptions.effect.opacity_min,
          fadein_min: showPopupOptions.effect.fadein_min
        }
      }
      const debugRequest: DebugRequestParamsDTO = getDebugRequest() || {}

      actions = baseDiscountPopup({
        preview: showPopupOptions.preview,
        popupId: showPopupOptions.popupId,
        campaignId: showPopupOptions.campaignId,
        effect,
        trigger: showPopupOptions.trigger,
        forcedSegments: debugRequest.fs,
        cartSize: showPopupOptions.cart ? showPopupOptions.cart.size : undefined,
        cartTotal: showPopupOptions.cart ? showPopupOptions.cart.total : undefined
      })
    },
    close() {
      if (actions) {
        actions.close()
      }
    }
  }

  return {
    internal,
    preview,
    previewById,
    open,
    okToOpen,
    openCheck,
    stampOnCheckoutPage,
    openMinimized,
    done,
    writePopupAttribute, // Export for testing
    readPopupAttributes // Export for testing
  }
}

export default discountPopup
