import { Maybe } from "@/types"
import { parseJSON } from "@/utils/json"

type ValidKey = `nosto:${string}`

interface Store {
  get(key: ValidKey): string | null
  set(key: ValidKey, value: string): void
  remove(key: ValidKey): void
  getAsJson<T>(key: ValidKey): Maybe<T>
  setAsJson<T>(key: ValidKey, value: T): void
}

function storeFromStorage(storage: Storage): Store {
  return {
    get(key: ValidKey) {
      return storage.getItem(key)
    },
    set(key: ValidKey, value: string) {
      storage.setItem(key, value)
    },
    remove(key: ValidKey) {
      storage.removeItem(key)
    },
    getAsJson<T>(key: ValidKey) {
      const value = storage.getItem(key)
      return parseJSON<T>(value)
    },
    setAsJson<T>(key: ValidKey, value: T) {
      storage.setItem(key, JSON.stringify(value))
    }
  }
}

export const localStore = storeFromStorage(localStorage)
export const sessionStore = storeFromStorage(sessionStorage)
