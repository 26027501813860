// based on https://github.com/MathisBullinger/froebel

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn<A = any, B = any> = (a: A) => B
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Last<T extends Fn[]> = T extends [...infer _, infer L extends Fn] ? L : never
type PipedInput<A extends Fn[]> = Parameters<A[0]>[0]
type PipedReturn<A extends Fn[]> = ReturnType<Last<A>>

type ValidChain<A extends Fn[]> = A extends [infer F extends Fn, infer G extends Fn, ...infer R extends Fn[]]
  ? ReturnType<F> extends Parameters<G>[0]
    ? [F, ...ValidChain<[G, ...R]>]
    : never
  : A extends [Fn]
  ? A
  : never

export default function pipe<A extends Fn[]>(...fns: ValidChain<A>) {
  return (arg: PipedInput<A>) => {
    return fns.reduce((acc, fn) => fn(acc), arg) as PipedReturn<A>
  }
}
