import settings from "@/core/settings"
import context from "@/core/context"
import * as shopifyCmp from "./shopify-cmp"
import * as magentoCmp from "./magento-cmp"
import bus from "@/core/api/bus"
import { addCMPAttribution } from "."
import logger from "@/core/logger"

export async function initCmp() {
  if (context.mode.isBot()) {
    logger.debug("Skipping CMP attribution for bots")
    return
  }
  bus.on("taggingsent", response => {
    if (response.cmpid) {
      addCMPAttribution(response.cmpid)
    }
  })
  switch (settings.cmpMode) {
    case "shopify":
      await shopifyCmp.handleCategoryUrls()
      break
    case "magento":
      await magentoCmp.handleCategoryUrls()
  }
}
