import settings from "@/core/settings"

export const LOCAL_STORAGE_KEY = "nosto:popup"

export function isPopupId(str: string) {
  return str.length === 24 && str.match(/^[0-9a-f]+$/)
}

export function lookupPopupId(campaignName: string) {
  if (!settings.discountPopupTriggers) {
    return undefined
  }

  for (const triggerKey in settings.discountPopupTriggers) {
    const triggerValues = settings.discountPopupTriggers[triggerKey]

    const matchingPopupSettings = triggerValues.find(triggerValue => triggerValue.name === campaignName)
    if (matchingPopupSettings) {
      return matchingPopupSettings.popup_id
    }
  }
  return undefined
}
