import { reportPopupAnalytics } from "../analytics"
import { clearPopupAttributes, writePopupAttribute } from "../popupAttributes"
import { PropsWithModal } from "../types"
import bus from "@/core/api/bus"
import context from "@/core/context"

/**
 * Closes a dialog and makes a clean-up
 */
export function closeDialog({ popupId, campaignId, preview, modal, dialog }: PropsWithModal) {
  if (!popupId) {
    return
  }

  modal.hide()
  modal.remove()
  dialog.remove()

  // cleanup cookies
  writePopupAttribute(popupId, "coupon", null)
  if (preview) {
    // cleanup cookies
    clearPopupAttributes(popupId)
  } else {
    // store 'popup closed' status to avoid showing pop-up\ribbon during this session
    writePopupAttribute(popupId, "state", "closed")
  }

  // send stats
  bus.emit("popupclosed", {
    campaignId: popupId
  })
  if (campaignId && !preview) {
    reportPopupAnalytics(campaignId, "closed")
  }

  context.popupShown = false
}
