import windows from "@/core/windows"
import { asyncPlacementMode, Queue } from "./placementQueue"
import { DynamicPlacementDTO } from "@/types"

const hasIntersectionObserver = "IntersectionObserver" in window

function elementVisible(element: Element) {
  queue.observer!.unobserve(element)
  queue.update(e => e === element)
}

const queue = new Queue<Element, IntersectionObserver>(
  () =>
    new IntersectionObserver(events => {
      events.forEach(ev => {
        if (ev.isIntersecting) {
          elementVisible(ev.target)
        }
      })
    })
)

/**
 * robust synchronous method to detect invisibility
 */
function isElementInViewport(el: Element): boolean {
  const rect = el.getBoundingClientRect()
  return !(
    rect.bottom < 0 ||
    rect.right < 0 ||
    rect.left > windows.site.innerWidth ||
    rect.top > windows.site.innerHeight ||
    (rect.bottom === 0 && rect.right === 0 && rect.left === 0 && rect.top === 0)
  )
}

/**
 * @param {Element} element
 * @param {string} divId
 * @param {Placement} configuration
 * @return {boolean} true element if placement delayed until is being observed
 */
export default function subscribeIntersectionObserver(
  element: Element,
  divId: string,
  configuration: Pick<DynamicPlacementDTO, "intersection">
) {
  // do not perform async placement management if no handler is defined in placementQueue
  if (
    !configuration.intersection ||
    !hasIntersectionObserver ||
    isElementInViewport(element) ||
    !asyncPlacementMode()
  ) {
    return false
  }

  // protect against multiple invocations
  const old = queue.queue(divId, element)
  if (old) {
    queue.observer!.unobserve(old)
  }
  queue.observer!.observe(element)
  return true
}
