import page from "@/core/page"
import context from "@/core/context"
import applyFilters from "@/core/filters/filters"
import settings from "@/core/settings"
import measurePerformance from "@/core/measurePerformance"
import { injectDynamicCampaign } from "./injection"
import subscribeIntersectionObserver from "./async/intersection"
import subscribeMutationObserver from "./async/mutation"
import { stopHidingDynamicPlacements } from "./placementHiding"
import { DynamicPlacementDTO } from "@/types"

function placements() {
  return settings.placements || {}
}

function availableDynamicPlacement(divIds = Object.keys(placements())) {
  const preview = context.mode.isPreview()
  return divIds.filter(divId => {
    const configuration = placements()[divId]
    if (!configuration || (!configuration.enabled && !preview)) {
      return false
    }
    return configuration.cssSelector && applyFilters(configuration.filters)
  })
}

type HandlePlacement = (e: HTMLElement | null, divId: string, configuration: DynamicPlacementDTO) => boolean | void

/**
 * Abstraction to iterate over dynamic placements:
 * Finds all or subset (divIds) of placements and performs actions upon present / absent placement (handlePlacement)
 *
 * @param {string[]?} divIds
 * @param {function(Element, string, Object):boolean} handlePlacement
 *
 * @return {string[]} divIds of placements that were enabled and deemed worthy by handlePlacement (handlePlacement returned true)
 */
function processPlacementsByCssSelector(handlePlacement: HandlePlacement, divIds?: string[]) {
  return availableDynamicPlacement(divIds).filter(divId => {
    const configuration = placements()[divId]
    const target = page.select(configuration.cssSelector!)
    return handlePlacement(target, divId, configuration)
  })
}

/**
 * Gets a list of placements present on the page and subscribes for new placements to appear
 */
const getPlacements = () => {
  return measurePerformance("nosto.get_dynamic_placements", () =>
    processPlacementsByCssSelector((target, divId, configuration) =>
      target
        ? !subscribeIntersectionObserver(target, divId, configuration)
        : subscribeMutationObserver(divId, configuration)
    )
  )
}

const injectCampaigns = (campaigns: Record<string, string>) => {
  return measurePerformance("nosto.inject_dynamic_campaigns", () => {
    stopHidingDynamicPlacements()
    const injectArguments: [HTMLElement, DynamicPlacementDTO, string, string][] = []
    const emptyCampaigns: [string, HTMLElement[]][] = []
    // decouple element search from injection so injection doesn't affect applicability of subsequent placements' selector
    processPlacementsByCssSelector((element, divId, config) => {
      // empty string campaign in considered no action
      if (element) {
        if (campaigns[divId]) {
          injectArguments.push([element, config, campaigns[divId], divId])
        } else {
          emptyCampaigns.push([divId, []])
        }
      }
    }, Object.keys(campaigns))
    return [...injectArguments.map(args => injectDynamicCampaign(...args)), ...emptyCampaigns]
  })
}

/**
 * Determines, if placement's configuration filters allow
 * placement to be displayed on this page.
 *
 * Use cases: debug toolbar
 */
const isFiltered = (placement: DynamicPlacementDTO) => applyFilters(placement.filters)

/**
 * Returns true if filtering configuration was set up for
 * a placement (by url of page type).
 * Use cases: debug toolbar
 */
const isFilteringConfigured = (placement: DynamicPlacementDTO) => (placement.filters || []).length > 0

const provider = {
  getPlacements,
  injectCampaigns,
  isFiltered,
  isFilteringConfigured
}

export default provider
export type DynamicProvider = typeof provider
