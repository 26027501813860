import context from "@/core/context"
import windows from "@/core/windows"
import settings from "./settings"
import axios from "axios"

function getPerformaneEntry(performance: Performance) {
  if (performance.getEntriesByType) {
    const entries = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[]
    return entries[0] ?? performance.timing
  }
  // fallback to legacy API
  return performance.timing
}

export default function reportTimes(nostoReady: Date, responseReady: Date) {
  const uri = new URL(`${settings.server}/jstiming1?m=${settings.account}`)

  const addParam = (param: string, val: number | Date) => {
    if (!val || val === 0) return
    const value = nostoReady.getTime() - +val
    uri.searchParams.append(param, value.toString())
  }
  try {
    // If browser supports html5 timing API
    if (typeof windows.site.performance !== "undefined") {
      const t = getPerformaneEntry(windows.site.performance)
      addParam("fromResponseEnd", t.responseEnd)
      addParam("fromResponseStart", t.responseStart)
      addParam("fromDomReady", t.domContentLoadedEventEnd)
      addParam("fromPageLoaded", t.loadEventEnd)
      addParam("fromPreRender", responseReady)
    }
  } catch (eInner) {
    // Ignore the exception. Some platforms handle
    // performance and performance.timing access inconsistently:
    // at least Mobile Chrome on iPhone and Firefox 27.0 on Win XP.
  }
  addParam("fromInclude", context.created.getTime())
  // @ts-expect-error legacy field, we don't know where this comes from
  if (context.loader && context.loader.l) {
    // @ts-expect-error legacy field, we don't know where this comes from
    const embedTs = context.loader.l
    addParam("fromEmbed", embedTs.getTime())
  }

  axios.get(uri.toString())
}
