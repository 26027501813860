/* eslint-disable no-use-before-define */
import bus, { Prerender } from "@/core/api/bus"
import { getDebugRequest } from "@/core/debug"
import { sessionStore } from "@/core/store"
import settings from "@/core/settings"
import context from "@/core/context"
import { CustomerAffinityResponse, CustomerAffinityResponseItem } from "@/types"
import ev1state from "@/core/ev1/state"
import { PersonalizationBoost, SearchSessionParams } from "./types"

type SessionParamsData = Pick<Prerender, "affinityScores" | "segments">

const cacheKey = "nosto:search:sessionParams:v2"
const sessionParamsFn = ev1state(transformToSearchSessionParams)

export function initSessionParams() {
  if (settings.searchEnabled) {
    // registering caching side effect on prerender
    bus.on("prerender", ({ affinityScores, segments }) => {
      const data = { affinityScores, segments }
      sessionStore.setAsJson(cacheKey, transformToSearchSessionParams(data))
    })
  }
}

export default async function getSearchSessionParams() {
  let sessionParams = sessionStore.getAsJson<SearchSessionParams>(cacheKey)
  if (!sessionParams && !context.mode.isBot()) {
    // cached version not available, use ev1state helpers to fetch data
    sessionParams = await sessionParamsFn()
  }
  return appendForcedSegments(sessionParams)
}

function getFieldBoosts(field: string, fieldAffinities: CustomerAffinityResponseItem[]) {
  return fieldAffinities.reduce<PersonalizationBoost[]>((acc, affinity) => {
    const existingBoostIndex = acc.findIndex(
      v => v.field === field && v.weight === parseFloat(affinity.score.toString())
    )
    if (existingBoostIndex > -1) {
      return acc.map((existingValue, index) =>
        index === existingBoostIndex
          ? {
              ...existingValue,
              value: [...existingValue.value, affinity.name.toLowerCase()]
            }
          : existingValue
      )
    }
    return [
      ...acc,
      {
        field,
        weight: parseFloat(affinity.score.toString()),
        value: [affinity.name.toLowerCase()]
      }
    ]
  }, [])
}

function transformToPersonalizationBoost(affinities: CustomerAffinityResponse): PersonalizationBoost[] {
  return [
    ...getFieldBoosts("affinities.categories", affinities.top_categories || []),
    ...getFieldBoosts("affinities.brand", affinities.top_brands || []),
    ...getFieldBoosts("affinities.productType", affinities.top_product_types || []),
    ...Object.keys(affinities.top_skus || {})
      .sort()
      .flatMap(skuField => getFieldBoosts(`affinities.${skuField}`, affinities.top_skus![skuField] || []))
  ]
}

function transformToSearchSessionParams({ affinityScores, segments }: SessionParamsData) {
  const personalizationBoost = affinityScores ? transformToPersonalizationBoost(affinityScores || {}) : []
  const segmentsData = segments?.active_segments?.map(segment => segment.id) || []

  return {
    segments: segmentsData,
    products: {
      personalizationBoost
    }
  }
}

function getForcedSegments() {
  const debugCookie = getDebugRequest()
  return debugCookie && debugCookie.fs && Array.isArray(debugCookie.fs) ? debugCookie.fs : []
}

function appendForcedSegments(data: SearchSessionParams = {}) {
  const forcedSegments = getForcedSegments()
  const segments = data.segments || []

  if (segments.length > 0 || forcedSegments.length > 0) {
    return {
      ...data,
      segments: [...segments, ...forcedSegments.filter(fs => !segments.includes(fs))]
    }
  }

  return data
}
