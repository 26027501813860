import bus from "@/core/api/bus"
import logger from "@/core/logger"
import settings from "@/core/settings"
import { ReqData } from "../types"
import { reqDataParams } from "../utils/utils"

export async function sendAbandonedCartEmail(reqData: ReqData, popupId: string) {
  let sendingError: unknown = null
  try {
    await sendAbandonedCartEmailUnsafe(reqData, popupId)
  } catch (error) {
    sendingError = error
    logger.error(`Failed to send email.`, error)
  }

  bus.emit("sendabandonedcartemail", {
    campaignId: popupId,
    email: reqData.email,
    sent: sendingError === null,
    message: sendingError
  })
}

async function sendAbandonedCartEmailUnsafe(reqData: ReqData, popupId: string) {
  const response = await fetch(`${settings.server}/campaigns/sendACE?${reqDataParams(reqData)}`, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })

  const jsonResponse = await response.json<{ sent: string | boolean; message: string }>()

  const { sent, message } = jsonResponse

  if (sent !== "true" && sent !== true) {
    logger.error(`Failed to send email: Server error.`)
    return
  }

  bus.emit("sendabandonedcartemail", {
    campaignId: popupId,
    email: reqData.email,
    sent,
    message
  })
}
