import context from "@/core/context"
import page from "@/core/page"
import { getMappings } from "."
import { parseJSON } from "@/utils/json"

type Mapping = Record<string, string>

export function applyRewrites(categoryToUrl: Mapping, categoryToAttribution: Mapping) {
  Object.keys(categoryToAttribution)
    .filter(cat => categoryToUrl[cat])
    .forEach(cat => {
      const url = categoryToUrl[cat]
      const cacheKey = categoryToAttribution[cat]
      page.selectAll(`a[href='${url}'], a[href^='${url}?']`).forEach(a => {
        const href = a.getAttribute("href")
        if (href && href.indexOf("?key=") === -1 && href.indexOf("&key=") === -1) {
          const separator = href.indexOf("?") > -1 ? "&" : "?"
          a.setAttribute("href", `${href}${separator}key=${cacheKey}`)
        }
      })
    })
}

export async function handleCategoryUrls() {
  const data = await getMappings<Mapping>("magento")
  context.site.domReady(() => {
    const element = page.select("#nosto-cmp-mapping")
    const nostoCmpMapping = element && parseJSON<Mapping>(element.innerHTML)
    if (nostoCmpMapping) {
      applyRewrites(nostoCmpMapping, data)
    }
  })
}
