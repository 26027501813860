import ctx from "@/core/context"
import { ResponseData } from "../requests/fetchPopupData"

export function loadResponseDataScripts(responseData: ResponseData) {
  const siteWindow = ctx.site.window
  const nostoWindow = ctx.nosto.window
  // Add contents to dialog and run script in context of iframe.
  let responseHtmlNode = siteWindow.document.createElement("div")
  responseHtmlNode.innerHTML = responseData.html
  if (responseHtmlNode.childNodes.length === 1) {
    // responseData.html is wrapped into div, so we expect to get one node only.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    responseHtmlNode = responseHtmlNode.childNodes[0] as HTMLDivElement
  }
  const responseDataScripts = Array.from(responseHtmlNode.querySelectorAll("script")).map(s => s.cloneNode(true)) // create nodes with script elements
  responseHtmlNode.querySelectorAll("script").forEach(s => s.remove()) // remove scripts from html we appending to main page
  siteWindow.document.body.append(responseHtmlNode) // append html/css to main page
  const dialog = responseHtmlNode
  responseDataScripts.forEach(script => {
    const newScript = document.createElement("script")
    if (script.hasAttributes()) {
      Array.from(script.attributes).forEach(attr => {
        newScript.setAttribute(attr.name, attr.value)
      })
    }
    newScript.innerHTML = script.innerHTML
    nostoWindow.document.body.append(newScript) // append scripts to iframe - to execute scripts in iframe context.
  })
  return dialog
}
