export default function (targetElement, win, effect) {
  const doc = win.document
  const OVERLAY_ELEMENT = "simpledialog-overlay"
  const ZINDEX = 20000
  const OPACITY = effect?.opacity_min || 0.8

  function getViewport() {
    return {
      x: win.pageXOffset || doc.documentElement?.scrollLeft || doc.body.scrollLeft,
      y: win.pageYOffset || doc.documentElement?.scrollTop || doc.body.scrollTop,
      w: win.innerWidth || doc.documentElement?.clientWidth || doc.body.clientWidth,
      h: win.innerHeight || doc.documentElement?.clientHeight || doc.body.clientHeight
    }
  }

  let styleStr = `top: 0; left: 0; z-index: ${ZINDEX}; display: none;background-color: #000;`
  let sizeSpec = "width: 100%; height: 100%;"
  styleStr += `position:fixed; opacity: ${OPACITY};${sizeSpec}`
  let overlayHTML = `<div src='javascript:false' id='${OVERLAY_ELEMENT}' style='${styleStr}'></div>`
  doc.querySelector("body").insertAdjacentHTML("beforeend", overlayHTML)
  const overlayElt = doc.querySelector(`#${OVERLAY_ELEMENT}`)

  function show() {
    const vp = getViewport()
    overlayElt.style.display = "block"
    if (effect?.fadein_min) {
      overlayElt.style.opacity = 0
      overlayElt.style.transition = `opacity ${effect.fadein_min / 1000}s`
      // delay via setTimeout is needed to get the transition effect
      setTimeout(() => {
        overlayElt.style.opacity = OPACITY
      }, 1)
    }
    const box = doc.querySelector(targetElement)
    if (box) {
      box.style.position = "absolute"
      box.style.display = "block"
      const styles = {
        zIndex: ZINDEX + 1,
        top: `${vp.y + (vp.h - box.offsetHeight) / 2}px`,
        left: `${vp.x + (vp.w - box.offsetWidth) / 2}px`
      }
      Object.assign(box.style, styles)
      if (effect?.fadein_min) {
        box.style.opacity = 0
        box.style.transition = `opacity ${effect.fadein_min / 1000}s`
        box.style.opacity = 1
        delete effect.fadein_min
      }
    } else {
      console.warn("Element not found")
    }
  }

  function hide() {
    overlayElt.style.display = "none"
    const element = doc.querySelector(targetElement)
    if (element) {
      element.style.display = "none"
    }
  }

  function remove() {
    doc.querySelector(`#${OVERLAY_ELEMENT}`)?.remove()
  }

  return {
    show,
    hide,
    remove
  }
}
