import type { PropsWithModal as ParentProps, ReqData } from "../types"
import { isAbandonedCartPopup } from "../utils/utils"

import { sendAbandonedCartEmail } from "../requests/sendAbandonedCartEmail"
import { fetchCouponCode, FetchCouponCodeProps } from "../requests/fetchCouponCode"
import { getNostoAddress, toggleElementVisibility } from "../utils/dom"
import { writePopupAttributeWithPreview } from "../popupAttributes"
import { showDialog } from "../dialog"
import { reportPopupAnalytics } from "../analytics"
import { setCustomer } from "@/core/loggedin"

function getEventTarget(event: MouseEvent) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return event.target as HTMLElement | undefined
}

type Props = ParentProps & {
  event: MouseEvent
  reqData: ReqData
  popupId: string
}

export function handleSendCoupon(props: Props) {
  const { event, dialog, reqData, modal, popupId, preview, campaignId, trigger } = props

  const eventTarget = getEventTarget(event)
  if (!eventTarget) {
    return
  }

  let newsletterConsent = !isAbandonedCartPopup(trigger)
  const nostoAddress = getNostoAddress(dialog)
  if (nostoAddress) {
    if (eventTarget.classList.contains("disabled")) {
      return
    }
    // disable the button to prevent multiple clicks
    eventTarget.classList.add("disabled")

    const newsletterCheckbox = dialog.querySelector<HTMLInputElement>("#nostoNewsletter")
    if (newsletterCheckbox) {
      newsletterConsent = newsletterCheckbox.checked
    }
    reqData.email = nostoAddress.value
    if (!preview) {
      void setCustomer({
        email: nostoAddress.value,
        newsletter: newsletterConsent,
        source: "discount-popup",
        source_id: campaignId ? campaignId : undefined,
        // TODO: Figure this out
        // These values were not present in original file, required by the TS definition
        first_name: "",
        last_name: ""
      })
      if (campaignId && !preview) {
        reportPopupAnalytics(campaignId, "email")
      }
    }
  }

  if (isAbandonedCartPopup(trigger)) {
    const formElements = [
      { selector: ".NostoForm", value: "none" },
      { selector: ".NostoThankYou", value: "" }
    ]
    toggleElementVisibility(dialog, formElements)

    void sendAbandonedCartEmail(reqData, popupId)
  }

  const couponCodeProps: FetchCouponCodeProps = {
    reqData,
    preview: !!preview,
    onSuccess: couponCode => {
      // Save value to cookie
      writePopupAttributeWithPreview(popupId, "coupon", preview, couponCode)
      // Updare dialog
      showDialog({ ...props, dialog, modal })
    }
  }

  void fetchCouponCode(couponCodeProps)
}
