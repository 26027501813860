import windows from "@/core/windows"
import context from "@/core/context"
import settings from "@/core/settings"
import pagetype from "@/core/page-type"
import bus from "@/core/api/bus"
import applyFilters from "@/core/filters/filters"

let stylesheet: HTMLStyleElement | null
let stylesheetText: string | null

function matchingHidePlacements(preview: boolean) {
  return Object.values(settings.placements).filter(
    placement => (preview || placement.enabled) && placement.cssSelector && placement.mode === "REPLACE"
  )
}

function taggingRead() {
  // maybe switch to other detection methods
  return pagetype()
}

function adjustHideStylesheet() {
  if (settings.disablePlacementAntiFlickering) {
    return
  }
  if (context.domHasLoaded) {
    return
  }

  const preview = context.mode.isPreview()
  const placements = matchingHidePlacements(preview)
  const matching = placements.filter(configuration => applyFilters(configuration.filters))

  const text = matching.map(placement => `${placement.cssSelector} { visibility: hidden !important; }`).join("\n")
  if (text !== stylesheetText) {
    stylesheetText = text
    stylesheet!.textContent = text
  }

  // some placements might be hidden later when there is tagging in place
  if (matching.length !== placements.length && !taggingRead()) {
    setTimeout(adjustHideStylesheet, 10)
  }
}

export function stopHidingDynamicPlacements() {
  if (stylesheet && stylesheet.parentNode) {
    stylesheet.parentNode.removeChild(stylesheet)
    stylesheet = null
    stylesheetText = null
  }
}

export function hideDynamicPlacements() {
  if (!settings.placements || !settings.live) {
    return
  }
  if (context.domHasLoaded) {
    return
  }
  const preview = context.mode.isPreview()
  if (!matchingHidePlacements(preview).length) {
    return
  }
  const doc = windows.site.document
  stylesheet = doc.createElement("style")
  adjustHideStylesheet()
  doc.head.appendChild(stylesheet)
  bus.on("servererror", stopHidingDynamicPlacements)
}
