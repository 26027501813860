"use strict"

/*
 domReady adapted from https://github.com/cms/domready which is adapted from jquery
 Exposed interface:
 let domReadyCreator = require('/domready.js');
 let domReady = domReadyCreator(window);
 domReady(function(){ CALLED ON DOMREADY });
 domReady(function(){ CALLED ON DOMREADY });
*/
export default function (targetWindow) {
  var targetDocument = targetWindow.document
  var w3c = !!targetDocument.addEventListener,
    loaded = false,
    toplevel = false,
    fns = []

  if (w3c) {
    targetWindow.addEventListener("DOMContentLoaded", contentLoaded, false)
    targetWindow.addEventListener("load", ready, false)
    poll()
  } else {
    targetDocument.attachEvent("onreadystatechange", contentLoaded)
    targetWindow.attachEvent("onload", ready)

    try {
      toplevel = targetWindow.frameElement === null
    } catch (e) {}

    if (targetDocument.documentElement.doScroll && toplevel) {
      scrollCheck()
    }
  }

  function poll() {
    //IE must wait for complete, others can start in "interactive", see: https://github.com/jquery/jquery/pull/901/files
    if (
      targetDocument.attachEvent ? targetDocument.readyState === "complete" : targetDocument.readyState !== "loading"
    ) {
      contentLoaded()
    } else {
      setTimeout(poll, 50)
    }
  }

  function contentLoaded() {
    if (w3c) {
      try {
        targetDocument.removeEventListener("DOMContentLoaded", contentLoaded, true)
      } catch (e) {
        /* Support Firefox 3.0: see https://bugzilla.mozilla.org/show_bug.cgi?id=563487 */
      }
    } else {
      if (targetDocument.readyState === "complete") {
        targetDocument.detachEvent("onreadystatechange", contentLoaded)
      }
    }

    ready()
  } // If IE is used, use the trick by Diego Perini
  // http://javascript.nwbox.com/IEContentLoaded/

  function scrollCheck() {
    if (loaded) {
      return
    }

    try {
      targetDocument.documentElement.doScroll("left")
    } catch (e) {
      // noinspection JSAnnotator
      targetWindow.setTimeout(arguments.callee, 15)
      return
    }

    ready()
  }

  function ready() {
    if (loaded) {
      return
    }

    loaded = true
    var len = fns.length,
      i = 0

    for (; i < len; i++) {
      fns[i].call(targetDocument)
    }
  }

  return function (fn) {
    // if the DOM is already ready,
    // execute the function
    return loaded ? fn.call(targetDocument) : fns.push(fn)
  }
}
