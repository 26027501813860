import context, { Context } from "@/core/context"
import { findForcedSegments } from "@/core/tagging/extract"
import createRequestBuilder, { RequestBuilder } from "@/core/request"
import { activateOverlay, popupCampaigns, getOverlay, openPopup, enablePopup, disablePopup } from "@/overlay"
import { setPlacementsCallback } from "@/placements/async/placementQueue"
import * as injection from "@/placements/injection"
import { load as loadToolbar } from "@/core/toolbar"
import { load as loadUnsubscribePanel } from "@/core/unsubscriber"
import settings, { modifySettings, Settings } from "@/core/settings"
import { recommendedProductAddedToCart } from "@/core/minicart"
import { reportCouponGiven as couponGiven } from "@/core/coupon"
import { setCustomer } from "@/core/loggedin"
import { setExperiments } from "@/core/abtesting"
import { addSegment as addSegmentCodeToVisit, getSegments } from "@/core/segments"
import { getCustomAffinities } from "@/core/customAffinities"
import { resendAllTagging as sendTagging, setTaggingProvider, pageTagging } from "@/core/tagging"
import logger from "@/core/logger"
import createSession, { sessionInstance } from "@/session/session"
import placements from "@/placements"
import { resendCartTagging, resendCustomerTagging } from "@/core/platform"
import { reportSearchImpression, recordSearch, recordSearchSubmit } from "@/search/analytics/impression"
import { recordSearchClick, storeSearchClick as reportSearchClick } from "@/search/analytics/click"
import search from "@/search/search"
import getSearchSessionParams from "@/search/sessionParams"
import bus, { EventMapping } from "./bus"
import { InsertMode, Maybe, PushedCustomer, PushedProduct } from "@/types"
import { PushedCart } from "../payload"
import { CART_POPUP_RECOMMENDATIONS, Event } from "../event"
import createAttribution from "./event/attribution"
import getCurrencyFormats from "@/search/currencyFormats"
import { isAutoLoad, setAutoLoad } from "./autoload"
import { Level } from "../logger/types"
import { Overlay } from "@/lib"

let loaded = false
let recommendationsEnabled = true

const captureError = (error: unknown, reporter: string, level: Level = "warn") => {
  logger[level](reporter, error)
}

const customer = (customer: PushedCustomer) => {
  // for public API calls, we are explicitly setting the source
  // eslint-disable-next-line no-param-reassign
  customer.source = "api"
  return setCustomer(customer)
}

type Install = {
  context: Context
  settings: Settings
  overlay: Overlay
}

const install = (callbackFn: (cb: Install) => void) => {
  // noinspection JSUnusedGlobalSymbols
  callbackFn({
    context,
    settings,
    overlay: getOverlay()
  })
}

/**
 * @deprecated Use settings directly. This was added for testing purposes.
 */
const getSettings = () => {
  return settings
}

const addPageTaggingToRequest = (request: RequestBuilder, sendElements: boolean, unwrappedReference?: string) => {
  const params = {
    data: pageTagging(),
    forcedSegments: findForcedSegments() || []
  }

  if (sendElements) {
    setPlacementsCallback(elements => {
      request.setElements(elements)
      void request.send({ skipEvents: true })
    })
    params.data.elements = placements.getPlacements()
  } else {
    params.data.elements = []
  }

  request.populateFrom(params, unwrappedReference)
}

const removeCampaigns = (divIds: string[]) => {
  divIds.forEach(placements.removeContent)
}

const showPlacementPreviews = (placement: { element: HTMLElement; mode: InsertMode }, content: string) => {
  void injection.updateElement(placement.element, placement.mode, content)
}

const injectedCampaigns = injection.injectedCampaigns

const defaultSession = () => {
  return sessionInstance
}

const createRecommendationRequest = (flags?: { includeTagging?: boolean }) => {
  const request = createRequestBuilder()
  if (flags && flags.includeTagging) {
    addPageTaggingToRequest(request, recommendationsEnabled)
  }
  return request
}

const setRecommendationsEnabled = (flag: boolean) => {
  recommendationsEnabled = flag
}

const listen = <T extends keyof EventMapping>(phase: T, callback: (...args: EventMapping[T]) => void) => {
  bus.on(phase, callback)
}

const load = () => {
  if (!loaded) {
    loaded = true
    return loadRecommendations()
  } else {
    return Promise.resolve()
  }
}

const loadRecommendations = (element?: string | { markNostoElementClicked: string }) => {
  const request = createRequestBuilder()
  const sendElements = recommendationsEnabled
  let unwrappedReference: Maybe<string> = undefined
  if (typeof element === "string") {
    unwrappedReference = element
  } else if (typeof element === "object") {
    unwrappedReference = element.markNostoElementClicked
  }
  addPageTaggingToRequest(request, sendElements, unwrappedReference)
  return request.loadRecommendations()
}

const loadCartPopupRecommendations = (products: PushedProduct[], cart: PushedCart, alwaysShow: boolean) => {
  const request = createRequestBuilder()
  addPageTaggingToRequest(request, false)

  if (products && products[0] && products[0].product_id) {
    request.addEvent({ type: CART_POPUP_RECOMMENDATIONS, target: products[0].product_id })
  }

  request.setCartContent(cart)
  return request.loadCartPopupRecommendations(alwaysShow)
}

const resendCartContent = async (cart: PushedCart) => {
  if (cart && cart.items) {
    await createRequestBuilder().setCartContent(cart).send({ skipPageViews: true })
    bus.emit("carttaggingresent", { cart_items: cart.items })
    return
  }
  logger.info("No cart info found.")
}

/**
 * API for recording attribution without triggering an ev1 request
 * @param event the #Event object
 */
const recordAttribution = (event: Event) => {
  return createAttribution().recordAttribution(event)
}

export default {
  captureError,
  customer,
  install,
  context,
  logger,
  activateOverlay,
  popupCampaigns,
  getOverlay,
  openPopup,
  enablePopup,
  disablePopup,
  pageTagging,
  couponGiven,
  loadToolbar,
  loadUnsubscribePanel,
  modifySettings,
  getSettings,
  addPageTaggingToRequest,
  removeCampaigns,
  showPlacementPreviews,
  injectedCampaigns,
  createSession,
  defaultSession,
  createRecommendationRequest,
  setAutoLoad,
  isAutoLoad,
  setRecommendationsEnabled,
  listen,
  load,
  loadRecommendations,
  loadCartPopupRecommendations,
  recommendedProductAddedToCart,
  setExperiments,
  setCustomer,
  resendCartContent,
  resendCartTagging,
  resendCustomerTagging,
  sendTagging,
  setTaggingProvider,
  addSegmentCodeToVisit,
  getSegments,
  getCustomAffinities,
  reportSearchClick,
  reportSearchImpression,
  getSearchSessionParams,
  search,
  recordSearch,
  recordSearchClick,
  recordSearchSubmit,
  recordAttribution,
  getCurrencyFormats
}
