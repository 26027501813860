import simpledialog from "@nosto/simple-dialog"
import windows from "@/core/windows"
import context from "@/core/context"
import settings from "@/core/settings"

export default async function () {
  const currentUri = context.siteUrl
  const serverUrl = settings.server
  const merchant = settings.account
  const targetWindow = windows.site
  const dialog = targetWindow.document.body.appendChild(document.createElement("div"))
  const f = simpledialog("#cartemailsettings", targetWindow)
  const params = currentUri.hash.split(/[-&?]/)
  const mail = params[1]
  const token = params[2]

  const searchParams = new URLSearchParams({
    account: merchant,
    mail,
    token
  })

  async function saveSettings() {
    const enablePlan = dialog.querySelector<HTMLInputElement>("#cartemailsettings input.cartEmailTogglePlan")?.checked
    const notificationsEnabled = dialog.querySelector<HTMLInputElement>(
      "#cartemailsettings input.cartEmailToggleAll"
    )?.checked
    const queryParams = new URLSearchParams({
      account: merchant,
      token,
      mail,
      enablePlan: String(enablePlan),
      notificationsEnabled: String(notificationsEnabled)
    })
    // update settings
    await fetch(`${serverUrl}/updateMailSettings?${queryParams}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    })
    dialog
      .querySelectorAll<HTMLElement>("#cartemailsettings .cartemailbody, #cartemailsettings .cartemailfooter")
      ?.forEach(el => (el.style.display = "none"))
    dialog
      .querySelectorAll<HTMLElement>("#successmsgbody, #successmsgfooter")
      ?.forEach(el => (el.style.display = "block"))
    dialog.querySelector("#cartemailsettings input.close")?.addEventListener("click", () => f.hide())
  }

  const settingsResponse = await fetch(`${serverUrl}/mailSettings?${searchParams}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const responseData: { html: string } = await settingsResponse.json()
  dialog.innerHTML = responseData.html
  dialog.querySelector("#cartemailsettings a.toggleSettings")?.addEventListener("click", () => {
    dialog
      .querySelectorAll<HTMLElement>("#notificationplansettings, #allnotificationsettings")
      .forEach(el => (el.style.display = "none"))
  })
  dialog.querySelector("#cartemailsettings input.cancel")?.addEventListener("click", () => f.hide())
  dialog.querySelector("#cartemailsettings input.save")?.addEventListener("click", saveSettings)

  f.show()
}
