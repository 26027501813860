import { AnalyticEventProperties, CategoryClick, Maybe, SearchClick, SearchEventMetadata } from "@/types"
import { sessionStore } from "@/core/store"
import { putAttribution } from "@/core/parameterlessAttribution"
import logger from "@/core/logger"
import { getVariations } from "../variations"
import { checkSearchMetadata, getEventProperties } from "./helpers"
import { reportAnalytics } from "./api"
import { getMetadataCache } from "./cache"
import bus from "@/core/api/bus"
import { assertValidClick, assertValidType } from "./validation"
import { SearchHit, SearchTrackOptions } from "./types"

const searchClickKey = "nosto:search:clickStorage"
const categoryClickKey = "nosto:category:clickStorage"

export async function reportSearchClick() {
  const searchClickData = sessionStore.getAsJson<SearchClick>(searchClickKey)
  if (searchClickData) {
    try {
      searchClickData.metadata = checkSearchMetadata("click", searchClickData.metadata)
      sessionStore.remove(searchClickKey)
      await reportAnalytics("search", "click", searchClickData)
    } catch (e) {
      logger.error("Could not report search click", e)
    }
  }

  const categoryClickData = sessionStore.getAsJson<CategoryClick>(categoryClickKey)
  if (categoryClickData) {
    try {
      sessionStore.remove(categoryClickKey)
      await reportAnalytics("category", "click", categoryClickData)
    } catch (e) {
      logger.error("Could not report category click", e)
    }
  }
}

/**
 * Record search click event
 */
export function recordSearchClick(type: SearchTrackOptions, hit: SearchHit) {
  if (type === "autocomplete" && "keyword" in hit) {
    logger.info("Skipping search click event for autocomplete with keyword", hit.keyword)
    return
  }
  assertValidType(type)
  assertValidClick(hit)
  const properties = getEventProperties(getVariations())

  if (type === "category") {
    const metadata = getMetadataCache(type).get()
    if (metadata?.category || metadata?.categoryId) {
      bus.emit("categoryclick", { productId: hit.productId, metadata, properties })
      sessionStore.setAsJson<CategoryClick>(categoryClickKey, { productId: hit.productId, metadata, properties })
    }
  } else if (type === "autocomplete") {
    const metadata = getMetadataCache(type).get()
    if (metadata) {
      metadata.isAutoComplete = true
      bus.emit("searchclick", { productId: hit.productId, metadata, properties })
      return storeSearchClick(hit.productId, metadata, hit.url, properties)
    }
  } else {
    const metadata = getMetadataCache(type).get()
    if (metadata) {
      metadata.isAutoComplete = false
      bus.emit("searchclick", { productId: hit.productId, metadata, properties })
      return storeSearchClick(hit.productId, metadata, hit.url, properties)
    }
  }
}

export function storeSearchClick(
  productId: string = "",
  metadata: SearchEventMetadata,
  productUrl: Maybe<string>,
  properties: Maybe<AnalyticEventProperties>
) {
  metadata = checkSearchMetadata("click", metadata)
  sessionStore.setAsJson<SearchClick>(searchClickKey, { productId, metadata, properties })
  // add VP ref via parameterlessAttribution
  if (productUrl && metadata.resultId) {
    putAttribution(productUrl, { ref: metadata.resultId })
  }
}
