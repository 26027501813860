import logger from "@/core/logger"

export default function toInt(text: string | undefined) {
  if (text) {
    try {
      const val = parseInt(text, 10)
      return isNaN(val) ? undefined : val
    } catch (e) {
      logger.warn(`Failed to parse int: ${text}`, e)
      return undefined
    }
  }
  return undefined
}
