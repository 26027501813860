import settings from "@/core/settings"
import visit from "@/core/visit"
import logger from "@/core/logger"
import { StatType } from "./types"

const analyticsEndpoints: { [K in StatType]?: string } = {
  shown: "triggered",
  couponFetched: "couponGiven"
}

export function reportPopupAnalytics(campaignId: string, statType: StatType) {
  if (Object.keys(analyticsEndpoints).includes(statType)) {
    const endpoint = analyticsEndpoints[statType]
    const data = {
      campaignId: campaignId
    }
    const blob = new Blob([JSON.stringify(data)])
    const url = new URL(`${settings.server}/analytics/popup/${endpoint}`)
    const customerId = visit.getCustomerId()
    url.searchParams.append("merchant", settings.account)
    if (customerId) {
      url.searchParams.append("c", customerId)
    }
    try {
      navigator.sendBeacon(url.toString(), blob)
    } catch (e) {
      logger.warn("Failed to send analytics beacon", e)
    }
  }
}
