import simpledialog from "@nosto/simple-dialog"
import type { Props as ParentProps } from "../types"
import { ResponseData } from "../requests/fetchPopupData"
import ctx from "@/core/context"

interface Props {
  effect: ParentProps["effect"]
  responseData: ResponseData
}

export function createDialog({ effect: effectParameter, responseData }: Props) {
  const campaignEffects = responseData.effect ?? {}

  // Use effects defined from api when available
  if (effectParameter && effectParameter.opacity_min) {
    campaignEffects.opacity_min = effectParameter.opacity_min
  }
  if (effectParameter && effectParameter.fadein_min) {
    campaignEffects.fadein_min = effectParameter.fadein_min
  }

  return simpledialog("#NostoPopUp", ctx.site.window, campaignEffects)
}
