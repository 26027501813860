import { AxiosError } from "axios"

export class ValidationError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export function isAxiosError<T>(err: unknown): err is AxiosError<T> {
  return !!(err && typeof err === "object" && "isAxiosError" in err && err.isAxiosError === true)
}

// check to ensure that the thrown errror is not already logged with logger
// Some of the nosto-api errors are logged immediately due to unhandled promise rejections not propagating
// e.g. nostojs(api => { api.defaultSession().viewProduct().load() })
export function isAlreadyLogged(err: unknown) {
  return err instanceof ValidationError
}
