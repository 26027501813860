import OpenChain, { Listener } from "./OpenChain"

export default class AfterScrollLink extends OpenChain {
  constructor(win: Window) {
    super(win)
    this.scrollTimeout = undefined
  }
  scrollTimeout?: number
  yOffset?: number
  supportPageOffset?: boolean
  isCSS1Compat?: boolean
  handleScrollClos?: () => void

  scrollFinished() {
    let scrollMin: number
    const scrolled = Math.abs(this.yOffset! - this.getY())
    for (let i = 0; i < this.listeners.length; i += 1) {
      scrollMin = this.listeners[i].config.scroll || 100
      if (scrollMin > scrolled || (this.listeners[i].okToOpen && !this.listeners[i].okToOpen())) {
        continue
      }
      this.listeners[i].cb()
      this.deregister(i)
    }
  }

  handleScroll(delay: number) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    if (this.scrollTimeout) {
      this.win.clearTimeout(this.scrollTimeout)
    }
    this.scrollTimeout = this.win.setTimeout(() => {
      self.scrollFinished()
    }, delay)
  }

  getY() {
    // IE compatibility
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
    if (this.supportPageOffset) {
      return this.win.pageYOffset
    }
    return this.isCSS1Compat ? this.win.document.documentElement.scrollTop : document.body.scrollTop
  }

  setup(listener: Listener) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const afterScrollDelay = 250
    if (this.listeners.length === 1) {
      // IE compatibility
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
      this.supportPageOffset = this.win.pageXOffset !== undefined
      this.isCSS1Compat = (this.win.document.compatMode || "") === "CSS1Compat"
      this.yOffset = this.getY()

      this.handleScrollClos = () => {
        self.handleScroll(afterScrollDelay)
      }
      if (this.win.addEventListener) {
        this.win.addEventListener("scroll", this.handleScrollClos, false)
      }
    }
  }

  teardown() {
    if (this.win.removeEventListener && this.handleScrollClos) {
      this.win.removeEventListener("scroll", this.handleScrollClos, false)
    }
    this.handleScrollClos = undefined
  }
}
