import settings from "@/core/settings"
import logger from "@/core/logger"
import { Props, ReqData } from "../types"
import { reqDataParams } from "../utils/utils"
import { reportPopupAnalytics } from "../analytics"
import { reportCouponGiven } from "@/core/coupon"

export type FetchCouponCodeProps = Pick<Props, "preview"> & {
  reqData: ReqData
  onSuccess: (couponCode: string) => void
}

export async function fetchCouponCode(props: FetchCouponCodeProps) {
  try {
    await fetchCouponCodeUnsafe(props)
  } catch (error) {
    logger.error(`Failed to fetch coupon.`, error)
  }
}

async function fetchCouponCodeUnsafe({ reqData, preview, onSuccess }: FetchCouponCodeProps) {
  const response = await fetch(`${settings.server}/campaigns/couponCode?${reqDataParams(reqData)}`, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const jsonResponse = await response.json<{ campaign: string; couponCode: string }>()

  const { campaign, couponCode } = jsonResponse

  onSuccess(couponCode)

  // Do not push stats in preview
  if (preview) {
    return
  }

  void reportCouponGiven(campaign, couponCode, false)
  if (!preview) {
    reportPopupAnalytics(campaign, "couponFetched")
  }
}
