import measurePerformance from "@/core/measurePerformance"
import settings from "@/core/settings"
import axios from "axios"

type CurrencySettings = {
  currencyBeforeAmount: boolean
  currencyToken?: string
  decimalCharacter?: string
  groupingSeparator?: string
  decimalPlaces: number
}

export type CurrencyFormats = { [code: string]: CurrencySettings }

export default async function currencyFormats() {
  const response = await measurePerformance("nosto.currencies", () =>
    axios.get<CurrencyFormats>(`${settings.server}/settings/${settings.account}/currencyFormats`)
  )
  return response.data
}
