import { readPopupAttributes } from "./readPopupAttributes"
import { PopupAttributes } from "./types"
import { LOCAL_STORAGE_KEY, isPopupId, lookupPopupId } from "./utils"
import { localStore } from "@/core/store"

export function writePopupAttribute<K extends keyof PopupAttributes>(
  popupId: string,
  key: K,
  val?: PopupAttributes[K] | null
) {
  // Support campaign name input for now - used for event response messsage's campaignId "cdc"
  if (!isPopupId(popupId)) {
    popupId = lookupPopupId(popupId)!
  }
  const popupAttributes = readPopupAttributes() || {}
  if (!popupAttributes[popupId]) {
    popupAttributes[popupId] = {}
  }
  if (key) {
    if (!val) {
      delete popupAttributes[popupId][key]
    } else {
      popupAttributes[popupId][key] = val
      if (key === "state" && val === "closed") {
        delete popupAttributes[popupId].campaignId
      }
    }
  } else {
    // Clear
    delete popupAttributes[popupId]
  }
  localStore.setAsJson(LOCAL_STORAGE_KEY, popupAttributes)
}

export function writePopupAttributeWithPreview<K extends keyof PopupAttributes>(
  popupId: string,
  key: K,
  preview: boolean | undefined,
  val?: PopupAttributes[K] | null
) {
  if (!preview) {
    writePopupAttribute(popupId, key, val)
  }
}
