import cookie from "@/core/cookie"
import logger from "@/core/logger"
import settings from "@/core/settings"
import api from "./api"
import { PushedProduct } from "@/types"
import { PushedCart } from "./payload"
import { parseJSON } from "@/utils/json"

interface CartUpdate {
  added_items: PushedProduct[]
  cart: PushedCart
}

function waitForAddToCartCookie() {
  let breakLoop = false
  window.addEventListener("beforeunload", () => {
    breakLoop = true
  })

  let timesWaitForFocus = 0
  let intervalId = 0
  const checkInterval = 200
  const checkCookie = () => {
    if (breakLoop) {
      if (intervalId) {
        window.clearInterval(intervalId)
      }
      return
    }

    // Do not show if current browser tab is not active. It is supported by newer browsers.
    // It still shows if there are 2 browser windows open and both of them are visible on the screen
    if (document.hidden === true) {
      return
    }

    const cookieName = "nosto.itemsAddedToCart"
    const cartUpdatedCookie = cookie.get(cookieName)
    if (cartUpdatedCookie) {
      // If it is not onFocus, but it is not document.hidden, it could be in the other browser window.
      // Wait max 3 seconds if it is still not been shown, then just show the popup here
      if (timesWaitForFocus < 3000 / checkInterval && document.hasFocus && !document.hasFocus()) {
        timesWaitForFocus += 1
        logger.debug("Not on focus, skip showing add to cart popup on this page.")
      } else {
        timesWaitForFocus = 0

        // Delete the cookie
        cookie.set(cookieName, null)

        const cartUpdate = parseJSON<CartUpdate>(decodeURIComponent(cartUpdatedCookie))
        void api.loadCartPopupRecommendations(cartUpdate.added_items, cartUpdate.cart, true)
      }
    } else {
      timesWaitForFocus = 0
    }
  }

  checkCookie()
  intervalId = window.setInterval(checkCookie, checkInterval)
}

export function watchAddToCartEvent() {
  if (settings.addToCartPopup !== true) {
    logger.debug("Add to cart popup feature is disabled, skip subscribing message channel")
    return
  }

  if (settings.triggerAddToCartPopupWithCookie) {
    waitForAddToCartCookie()
  }
}
