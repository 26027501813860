import { Maybe } from "@/types"
import { Condition, PopupEffect, ResponseData } from "./types"
import { PopupTrigger } from "@/core/popups/types"

function arrayIndexOf<T>(arr: T[], obj: T, start: number = 0) {
  for (let i = start, j = arr.length; i < j; i += 1) {
    if (arr[i] === obj) {
      return i
    }
  }
  return -1
}

interface Config {
  reEntryTolerance?: number
  links?: OpenChain[]
  delay?: number
  scroll?: number
}

type OkToOpen = (popupId: string, condition: Condition, response?: ResponseData) => boolean
type Callback = (popupId: string, response: ResponseData, effect: PopupEffect, trigger: PopupTrigger) => void

export interface Listener {
  reEnterTimeout?: number
  okToOpen: () => boolean
  config: Config
  cb: () => void
}

export default class OpenChain {
  constructor(win: Window) {
    this.win = win
    this.listeners = []
  }

  win: Window
  listeners: Listener[]

  register(
    okToOpen: OkToOpen,
    config: Config,
    cb: Callback,
    popupId: string,
    condition: Condition,
    response: ResponseData,
    effect: PopupEffect,
    trigger: PopupTrigger
  ) {
    let linkCallback: () => void
    let linkOkToOpen: () => boolean
    let nextLink: Maybe<OpenChain>
    let listener: Listener
    const links: OpenChain[] = config.links || []
    if (!links || links.length === 0) {
      linkOkToOpen = () => okToOpen(popupId, condition, response)
      linkCallback = () => {
        if (linkOkToOpen()) {
          cb(popupId, response, effect, trigger)
        }
      }
    } else {
      nextLink = links.shift()
      config.links = links
      linkCallback = () => {
        nextLink!.register(okToOpen, config, cb, popupId, condition, response, effect, trigger)
      }
    }

    // eslint-disable-next-line prefer-const
    listener = {
      okToOpen: linkOkToOpen!,
      config,
      cb: linkCallback
    }
    this.listeners.push(listener)
    this.setup(listener)
  }

  deregister(listenerIndex: number) {
    this.listeners.splice(listenerIndex, 1)
    if (this.listeners.length === 0) {
      this.teardown()
    }
  }

  setup(listener: Listener) {
    listener.cb()
    if (this.listeners.length > 0) {
      this.deregister(arrayIndexOf(this.listeners, listener))
    }
  }

  teardown() {
    // abstract
  }
}
