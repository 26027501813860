import { Customer, LineItem, Order, TaggedCart, TaggedLineItem, TaggedProduct } from "./types"
import { nostojs } from "./api"
import { Cart, CartItem, PageType, PushedCustomer, WebsiteOrder, Product } from "@nosto/client-lib"

function toString<T>(value: T) {
  return typeof value === "number" ? String(value) : value
}

export function toNewsletter(marketing_permission: unknown) {
  if (typeof marketing_permission === "boolean") {
    return marketing_permission
  }
  return undefined
}

export function customerToNosto(customer: Customer): PushedCustomer {
  return {
    email: customer.email,
    customer_reference: customer.customer_reference,
    newsletter: toNewsletter(customer.marketing_permission)
  } as PushedCustomer
}

export function productToNosto({ product_id }: TaggedProduct): Product {
  return {
    product_id: toString(product_id)
  }
}

export function cartItemToNosto({ product_id, sku_id, ...rest }: TaggedLineItem): CartItem {
  return {
    product_id: toString(product_id),
    sku_id: toString(sku_id),
    ...rest
  }
}

export function cartToNosto({ items }: TaggedCart): Cart {
  return {
    items: items.map(cartItemToNosto)
  }
}

export function orderToNosto(order: Order): WebsiteOrder {
  return {
    info: {
      order_number: toString(order.orderId),
      email: order.billingAddress.email,
      first_name: order.billingAddress.firstName,
      last_name: order.billingAddress.lastName,
      type: "order"
    } as WebsiteOrder["info"],
    items: [...order.lineItems.physicalItems, ...order.lineItems.digitalItems].map(item =>
      orderItemToNosto(item, order.currency.code)
    ),
    payment_provider: Array.isArray(order.payments) ? order.payments[0].description : undefined!
  } as WebsiteOrder
}

export function orderItemToNosto(item: LineItem, currency: string): CartItem {
  return {
    product_id: toString(item.productId),
    sku_id: toString(item.variantId),
    quantity: item.quantity,
    name: item.name!,
    unit_price: unitPrice(item),
    price_currency_code: currency
  }
}

/*
 * Calculate a physical item's price, taking into account any discounts.
 */
export function unitPrice(item: LineItem) {
  const discounts = item.discounts ? Object.values(item.discounts) : []
  if (discounts.length) {
    const totalDiscount = Object.values(discounts)
      // Discount values can be a string or float.
      .map(Number)
      .filter(v => !Number.isNaN(v))
      .reduce((total, v) => total + v, 0)
    const discount = totalDiscount / item.quantity
    return item.salePrice! - discount
  }
  return item.salePrice!
}

export function pageTypeToNosto(pageType: string): PageType {
  switch (pageType) {
    case "category":
    case "product":
    case "cart":
    case "search":
      return pageType
    case "default":
      return "front"
    case "orderconfirmation":
      return "order"
    case "404":
      return "notfound"
    default:
      return "other"
  }
}

export function updatePageTypeTagging(pageType: string) {
  // XXX needed for Search implementations that use the DOM directly
  addLegacyTagging("nosto_page_type", pageType)
  const nostoPageType = pageTypeToNosto(pageType)
  nostojs(api => api.internal.setTaggingProvider("pageType", () => nostoPageType))
}

export function updateCustomerTagging(customer: Customer) {
  if (customer.group_id) {
    nostojs(api => api.internal.setTaggingProvider("variation", () => customer.group_id))
  }
  const nostoCustomer = customerToNosto(customer)
  nostojs(api => api.internal.setTaggingProvider("customer", () => nostoCustomer))
}

export function updateSearchTermTagging(searchTerm: string) {
  nostojs(api => api.internal.setTaggingProvider("searchTerms", () => [searchTerm]))
}

export function updateCategoryTagging(category: string) {
  // XXX needed for Search implementations that use the DOM directly
  addLegacyTagging("nosto_category", category)
  nostojs(api => api.internal.setTaggingProvider("categories", () => [category]))
}

export function updateProductTagging(product: TaggedProduct) {
  const nostoProduct = productToNosto(product)
  nostojs(api => api.internal.setTaggingProvider("products", () => [nostoProduct]))
}

export function updateCartTagging(cart: TaggedCart) {
  const nostoCart = cartToNosto(cart)
  nostojs(api => api.internal.setTaggingProvider("cart", () => nostoCart))
}

export function updateOrderTagging(order: Order) {
  const nostoOrder = orderToNosto(order)
  nostojs(api => api.internal.setTaggingProvider("order", () => nostoOrder))
}

export function addLegacyTagging(className: string, value: string) {
  let element = document.querySelector<HTMLElement>(`.${className}`)
  if (!element) {
    element = document.createElement("div")
    element.className = className
    element.style.display = "none"
    element.setAttribute("translate", "no")
    document.body.appendChild(element)
  }
  element.textContent = value
}
