export function getNostoAddress(dialog: HTMLElement) {
  return dialog.querySelector<HTMLInputElement>("#nostoAddress")
}

export function toggleElementVisibility(dialog: HTMLElement, elements: { selector: string; value: string }[]) {
  elements.forEach(({ selector, value }) => {
    const element = dialog.querySelector<HTMLElement>(selector)
    if (element) {
      element.style.display = value
    }
  })
}
