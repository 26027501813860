import { localStore } from "@/core/store"
import { ABTest } from "./types"
import { ForcedTestDTO } from "@/types"
import context from "@/core/context"

const abTestsName = "nosto:abTests"

function toABTest(t: ForcedTestDTO) {
  return {
    id: t.t,
    activeVariation: {
      id: t.v
    }
  }
}

export function getVariations() {
  const tests = localStore.getAsJson<ABTest[]>(abTestsName) ?? []
  const additions = context.mode.getDebugState()?.tp?.t?.map(toABTest)
  // add debug mode tests, when available
  return additions ? [...tests, ...additions] : tests
}

export function storeVariations(abTests: ABTest[]) {
  localStore.setAsJson(abTestsName, abTests)
}

export function setVisitId(visit: string) {
  if (visit) {
    const previousVisit = localStore.get("nosto:visit")
    localStore.set("nosto:visit", visit)
    if (previousVisit && visit !== previousVisit) {
      localStore.remove(abTestsName)
    }
  }
}
