import context from "@/core/context"
import windows from "@/core/windows"
import page from "@/core/page"
import settings from "@/core/settings"
import { getMappings } from "."
import { Maybe } from "@/types"

function findCategoryLinks() {
  return page.selectAll("a").filter(a => {
    const href = a.getAttribute("href")
    return (
      href && href.indexOf("nosto=") === -1 && href.indexOf("/collections/") > -1 && href.indexOf("/products/") === -1
    )
  })
}

type Callback = (e: HTMLElement) => void
type TimeoutHandle = ReturnType<typeof setTimeout>

function findCategoryLinksAndObserve(cb: Callback) {
  findCategoryLinks().forEach(cb)
  if (windows.site.MutationObserver) {
    let handle: TimeoutHandle | null = null
    const observer = new windows.site.MutationObserver(mutations => {
      if (mutations.filter(e => e.addedNodes).length) {
        if (handle) clearTimeout(handle)
        handle = setTimeout(() => findCategoryLinks().forEach(cb), 50)
      }
    })
    observer.observe(page.select("body")!, {
      subtree: true,
      childList: true
    })
  }
}

function getHandleFromUrl(url: string): Maybe<string> {
  return (url.match(/\/collections\/([\w-]*)/) || [])[1]
}

function newCollectionUrl(url: string, oldHandle: string, newHandle: string) {
  return url.replace(`/collections/${oldHandle}`, `/collections/${newHandle}`)
}

type Rewrites = Record<string, string>

export function applyRewrites(rewrites: Rewrites) {
  const url = context.siteUrlCleaned
  const handle = getHandleFromUrl(url)
  if (handle && rewrites[handle] && settings.shopifyCmpRedirect) {
    windows.site.document.location.href = newCollectionUrl(url, handle, rewrites[handle])
  } else if (Object.keys(rewrites).length) {
    context.site.domReady(() => {
      findCategoryLinksAndObserve(a => {
        const href = a.getAttribute("href")
        if (href) {
          const handle = getHandleFromUrl(href)
          if (handle && rewrites[handle]) {
            a.setAttribute("href", newCollectionUrl(href, handle, rewrites[handle]))
          }
        }
      })
    })
  }
}

export async function handleCategoryUrls() {
  const data = await getMappings<Rewrites>("shopify")
  return applyRewrites(data)
}
