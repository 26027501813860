import { Product } from "@/core/tagging/types"

function setValue<K extends keyof Product>(obj: Product, propName: K, val: string | number) {
  if (val) {
    // eslint-disable-next-line no-param-reassign
    obj[propName] = val as Product[K]
  }
}

function toFloat(val: string | number) {
  return typeof val === "string" ? parseFloat(val) : val
}

/**
 * Updates the rating and review counts to the product if the value isn't already
 * set.
 *
 * @param {Product} product the product object whose rating and reviews are to ne updated
 * @param {String|Number} bestRating the score of the best-rating of the product
 * @param {String|Number} ratingValue the average score of the rating of the product
 * @param {string} reviewCount the count of number of the reviews on product
 * @param {string} ratingCount the count of number of the ratings on product
 */
export default function update(
  product: Product,
  bestRating: string | number,
  ratingValue: string | number,
  reviewCount: string,
  ratingCount: string
) {
  if (!product.rating_value) {
    setValue(product, "rating_value", ratingValue)

    if (bestRating) {
      // eslint-disable-next-line no-param-reassign
      bestRating = toFloat(bestRating)
      if (bestRating && bestRating > 0) {
        // eslint-disable-next-line no-param-reassign
        ratingValue = toFloat(product.rating_value!)
        setValue(product, "rating_value", (ratingValue / bestRating) * 5)
      }
    }
  }
  if (!product.review_count) {
    setValue(product, "review_count", reviewCount)
    if (!product.review_count) {
      setValue(product, "review_count", ratingCount)
    }
  }
}
