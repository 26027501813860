import bus from "@/core/api/bus"
import { PropsWithModal as ParentProps, ReqData } from "../types"
import { handleSendCoupon } from "./handleSendCoupon"
import { closeDialog, hideDialog, showDialog } from "../dialog"
import { writePopupAttributeWithPreview } from "../popupAttributes"
import { reportPopupAnalytics } from "../analytics"

type Props = ParentProps & {
  popupId: string
  campaignId: string
  reqData: ReqData
}

export function attachEventHandlers(props: Props) {
  const { dialog, popupId, campaignId, preview } = props
  // attach handlers
  const nostoRibbonPopUp = dialog.querySelector(".NostoRibbonPopUp")
  if (nostoRibbonPopUp) {
    nostoRibbonPopUp.addEventListener("click", () => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const el = dialog.querySelector("#NostoRibbon") as HTMLElement
      el.style.display = "none"
      showDialog(props)
      bus.emit("popupmaximized", {
        campaignId: popupId
      })
      // send stats
      if (campaignId && !preview) {
        reportPopupAnalytics(campaignId, "maximized")
      }
    })
  }

  dialog.querySelectorAll(".NostoOverlayClosePermanently, .NostoRibbonClose").forEach(el => {
    el.addEventListener("click", () => closeDialog(props))
  })

  const nostoRibbon = dialog.querySelector("#NostoRibbon")
  dialog.querySelectorAll(".NostoOverlayClose").forEach(el => {
    el.addEventListener("click", () => {
      if (!nostoRibbon) {
        closeDialog(props)
      } else {
        hideDialog(props)
      }
      bus.emit("popupminimized", {
        campaignId: popupId
      })
      if (campaignId && !preview) {
        reportPopupAnalytics(campaignId, "minimized")
      }
    })
  })

  const nostoContinueBtn = dialog.querySelector("#nostoContinueBtn")
  if (nostoContinueBtn) {
    nostoContinueBtn.addEventListener("click", () => {
      if (!nostoRibbon) {
        closeDialog(props)
      }
      hideDialog(props)
      bus.emit("popupminimized", {
        campaignId: popupId
      })
      if (campaignId && !preview) {
        reportPopupAnalytics(campaignId, "minimized")
      }
    })
  }

  const nostoGoToCheckout = dialog.querySelector(".NostoGoToCheckout")
  if (nostoGoToCheckout) {
    nostoGoToCheckout.addEventListener("click", () => {
      writePopupAttributeWithPreview(popupId, "checkout", preview, true)
      writePopupAttributeWithPreview(popupId, "campaignId", preview, campaignId)
      hideDialog(props)
    })
  }

  const nostoOverlaySend = dialog.querySelector("#nostoOverlaySend")
  const nostoCouponGetBtn = dialog.querySelector("#nostoCouponGetBtn")

  if (nostoOverlaySend) {
    nostoOverlaySend.addEventListener("click", event =>
      handleSendCoupon({
        ...props,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        event: event as MouseEvent
      })
    )
  }

  if (nostoCouponGetBtn) {
    nostoCouponGetBtn.addEventListener("click", event =>
      handleSendCoupon({
        ...props,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        event: event as MouseEvent
      })
    )
  }
}
