import { Event, EXTERNAL_CAMPAIGN, SOURCE } from "@/core/event"
import { currentAttribution } from "./parameterlessAttribution"
import { ParseUriResult } from "@/utils/parseuri"

const params = ["utm_campaign", "utm_content", "utm_term", "utm_medium", "utm_source"]

export function campaignEvent(currentUri: ParseUriResult) {
  const target = params.map(param => currentUri.searchParams.get(param)).find(Boolean)
  if (target) {
    return { type: EXTERNAL_CAMPAIGN, target } as const
  }
}

export function isCustomCampaign(currentUri: ParseUriResult, segmentUrlParameters: string[]) {
  return segmentUrlParameters && segmentUrlParameters.filter(el => currentUri.searchParams.get(el))[0]
}

export function adWordEvent(currentUri: ParseUriResult) {
  if (currentUri.searchParams.get("gclid")) {
    return { type: EXTERNAL_CAMPAIGN, target: currentUri.searchParams.get("gclid")! } as const
  }
}

export function searchQuery(currentUri: ParseUriResult, siteSearchQueryParameterName: string) {
  if (!currentUri.searchParams) {
    return null
  }
  let searchQueryValue = currentUri.searchParams.get(siteSearchQueryParameterName)
  if (searchQueryValue) {
    // try decoding
    try {
      searchQueryValue = decodeURIComponent(searchQueryValue)
    } catch (e1) {
      try {
        searchQueryValue = unescape(searchQueryValue)
      } catch (e2) {
        // do nothing
      }
    }
    searchQueryValue = searchQueryValue.replace(/\+/g, " ")
  }
  return searchQueryValue
}

export function sourceEvent(
  currentUri: ParseUriResult,
  nostoSourceParameterName: string,
  supportedTypes: string[],
  recommendationRefParamName: string
) {
  const sourceString = currentUri.searchParams.get(nostoSourceParameterName) || currentAttribution().src
  if (sourceString && (supportedTypes || []).includes(sourceString)) {
    const srcEvent: Event = { type: SOURCE, target: sourceString }
    const recRef = currentUri.searchParams.get(recommendationRefParamName) || currentAttribution().ref
    if (recRef) {
      srcEvent.ref = recRef
    }

    return srcEvent
  }
}

export function recommendationRef(currentUri: ParseUriResult, recommendationRefParamName: string) {
  return searchQuery(currentUri, recommendationRefParamName)
}

export function refMail(currentUri: ParseUriResult) {
  return currentUri.searchParams.get("refmail")
}
