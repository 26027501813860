import { schemaFn, select } from "./utils/schema"
import { PluginMetadata } from "@/core/tagging/types"
import windows from "@/core/windows"

function getContent(element: HTMLElement | undefined) {
  return element?.getAttribute("content") ?? undefined
}

const metadataFn = schemaFn<PluginMetadata>({
  mainModule: select("meta[name=nosto-version]", getContent),
  cmpModule: select("meta[name=nosto-cmp-version]", getContent),
  msiModule: select("meta[name=nosto-msi-version]", getContent)
})

export default function findPluginVersions() {
  const document = windows.site.document
  if (!document) {
    return undefined
  }
  return metadataFn(document)
}
