function parse(href: string): ParseUriResult {
  const { protocol, hostname, hash, search, searchParams } = new URL(href)
  return { protocol, hostname, hash, search, searchParams, href }
}

export type ParseUriResult = Pick<URL, "href" | "protocol" | "hostname" | "hash" | "search" | "searchParams">

export default function parseUri(str: string | undefined) {
  if (!str) return parse(window.location.href)
  if (/^(file|http|https):\/\//.test(str)) return parse(str)
  return parse(window.location.protocol + "//" + str)
}
