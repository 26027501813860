import logger from "@/core/logger"
import {
  CartItem,
  EventRequestMessageV1,
  CustomerAffinityResponse,
  DebugToolbarDataDTO,
  EventResponseMessage,
  Experiment,
  PushedCustomer,
  SegmentsResponseBean,
  CategoryClick,
  SearchClick,
  SearchImpression,
  CategoryImpression
} from "@/types"
import { Level } from "../logger/types"
import { OrderError } from "@/order/track"
import { isPromise } from "@/utils/isPromise"
import { SearchQuery, SearchResult } from "@/search/types"
import { TaggingData } from "../tagging/types"

/**
 * Payload for prerender event
 *
 * @group Core
 */
export interface Prerender {
  customerId?: string
  affinityScores: CustomerAffinityResponse
  geoLocation: string[]
  eventDate: Date
  pageViews: number
  segments: SegmentsResponseBean
}

/**
 * Payload for postrender event
 *
 * @group Core
 */
export interface Postrender {
  responseData: Record<string, unknown>
  filledElements: string[]
  unFilledElements: string[]
}

/**
 * Payload for setexperiments event
 *
 * @group Core
 */
export interface Setexperiments {
  experiments: Experiment[]
}

/**
 * Payload for coupongiven event
 *
 * @group Core
 */
export interface Coupongiven {
  coupon_code: string
  coupon_campaign: string
  coupon_used: boolean
}

/**
 * Payload for scripterror event
 *
 * @group Core
 */
export interface Scripterror {
  msg: string
  stack?: string
  level: Level
}

/**
 * Payload for addtocart event
 *
 * @group Core
 */
export interface Addtocart {
  productId: string
  placementId: string
}

/**
 * Payload for carttaggingresent event
 *
 * @group Core
 */
export interface Carttaggingresent {
  cart_items: CartItem[]
  restore_link?: string
}

/**
 * Payload for setsegments event
 *
 * @group Core
 */
export interface Segments {
  segment: string
}

export interface SearchSuccessEventDTO {
  query: SearchQuery
  graphqlQuery: string
  graphqlVariables: object
  response: SearchResult
}

export interface SearchFailureEventDTO {
  query: SearchQuery
  graphqlQuery: string
  graphqlVariables: object
  error: string
}

/**
 * Payload for popupopened event
 *
 * @group Core
 */
export interface Popupopened {
  campaignId: string
  error?: unknown
  type: "api" | string // TODO: Should be made stricter
}

/**
 * Payload for popupmaximized, popupminimized, popupclosed and popupribbonshown events
 *
 * @group Core
 */
export interface Popup {
  campaignId: string
}

type LifecyleEvents = {
  prerequest: [EventRequestMessageV1]
  prerender: [Prerender]
  postrender: [Postrender]
  taggingsent: [EventResponseMessage]
  taggingresent: [TaggingData]
  carttaggingresent: [Carttaggingresent]
  customertaggingresent: [PushedCustomer]
  emailgiven: [PushedCustomer]
  scripterror: [Scripterror]
  servererror: [string[]]
}

type PopupEvents = {
  popupopened: [Popupopened]
  popupmaximized: [Popup]
  popupminimized: [Popup]
  coupongiven: [Coupongiven]
  popupclosed: [Popup]
  popupribbonshown: [Popup]
  sendabandonedcartemail: [object]
}

type InternalEvents = {
  ordererror: [OrderError]
  setexperiments: [Setexperiments]
  setsegments: [Segments]
  setcustomaffinities: []
  setcart: []
  addtocart: [Addtocart]
  ev1end: []
  debugdata: [DebugToolbarDataDTO]
  searchsuccess: [SearchSuccessEventDTO]
  searchfailure: [SearchFailureEventDTO]
  searchclick: [SearchClick]
  searchimpression: [SearchImpression]
  categoryclick: [CategoryClick]
  categoryimpression: [CategoryImpression]
}

/**
 * Mapping from event name to payload type
 *
 * @interface
 */
export type EventMapping = LifecyleEvents & PopupEvents & InternalEvents

type E = keyof EventMapping
type Callback<T extends E> = (...args: EventMapping[T]) => void
type Callbacks = { [K in E]?: Callback<K>[] }
type Payloads = { [K in E]?: EventMapping[K] }

const callbacks: Callbacks = {}
const lastPayload: Payloads = {}

async function invoke<T extends E>(event: T, fn: Callback<T>, args: EventMapping[T]) {
  try {
    const result = fn(...args)
    if (isPromise(result)) {
      // await promise to get the rejection also logged
      await result
    }
  } catch (e) {
    logger.warn(`Error in ${event} listener`, e)
  }
}

const bus = {
  on<T extends E>(event: T, fn: Callback<T>) {
    callbacks[event] = callbacks[event] || []
    callbacks[event]!.push(fn)
    if (lastPayload[event]) {
      void invoke(event, fn, lastPayload[event]!)
    }
  },
  emit<T extends E>(event: T, ...args: EventMapping[T]) {
    lastPayload[event] = args
    callbacks[event]?.forEach(fn => invoke(event, fn, args))
  }
}

export default bus
