import { readPopupAttributes } from "./readPopupAttributes"
import { LOCAL_STORAGE_KEY } from "./utils"
import { localStore } from "@/core/store"

export function clearPopupAttributes(popupId: string) {
  const popupAttributes = readPopupAttributes() || {}
  if (!popupAttributes[popupId]) {
    popupAttributes[popupId] = {}
  }
  delete popupAttributes[popupId]
  localStore.setAsJson(LOCAL_STORAGE_KEY, popupAttributes)
}
