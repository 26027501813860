import axios from "axios"
import handler from "@/core/ev1/handler"
import logger from "@/core/logger"
import windows from "./windows"
import { EventRequestMessageV1 } from "@/types"
import { toError } from "@/utils/toError"

type Callback = () => void

function invoke(cb: Callback) {
  cb()
}

type Options = {
  module?: boolean
}

export default function createLoader(winFn: () => Window = () => windows.nosto) {
  /**
   * @type Object.<function[]>
   */
  const loaded: Record<string, Callback[]> = {}

  function evalScriptData(data: string) {
    winFn().eval.call(winFn(), data)
  }

  function loadScript(url: string, callbackFn?: Callback, options?: Options) {
    return new Promise<void>((resolve, reject) => {
      try {
        const doc = winFn().document
        const head = doc.getElementsByTagName("head")[0] || doc.documentElement
        let script = doc.createElement("script")
        script.async = true
        script.src = url
        if (options?.module) {
          script.type = "module"
        }

        script.onerror = () => {
          logger.warn(`Error loading ${url}`)
          reject()
        }
        // eslint-disable-next-line no-multi-assign
        script.onreadystatechange = script.onload = () => {
          const state = script.readyState
          if (!state || /loaded|complete/.test(state)) {
            // Remove the script
            if (script.parentNode) {
              script.parentNode.removeChild(script)
            }

            // Dereference the script
            // @ts-expect-error explicit dereference
            script = undefined

            if (callbackFn) {
              callbackFn()
            }
            resolve()
          }
        }

        // use body if available. more safe in IE
        ;(doc.body || head).appendChild(script)
      } catch (e) {
        logger.warn("Error loading script", e)
        throw toError(e)
      }
    })
  }

  function loadOnce(url: string, callbackFn: () => void) {
    try {
      if (!loaded[url]) {
        loaded[url] = []
        loaded[url].push(callbackFn)

        void loadScript(url, () => {
          while (loaded[url].length > 0) {
            const cb = loaded[url].shift()!
            cb()
          }
          // @ts-expect-error FIXME
          loaded[url].push = invoke
        })
      } else {
        loaded[url].push(callbackFn)
      }
    } catch (e) {
      logger.warn("Error loading script", e)
      throw toError(e)
    }
  }

  function evalScript(elem: HTMLScriptElement, callbackFn: () => void, aliasUrl: string) {
    try {
      if (elem.src) {
        loadOnce(elem.src, callbackFn)
      } else {
        const data = elem.text || elem.textContent || elem.innerHTML || ""
        // https://developers.google.com/web/tools/chrome-devtools/javascript/source-maps
        const script = `${data}\n//@ sourceURL=${aliasUrl}\n//# sourceURL=${aliasUrl}`

        if (data) {
          // ( win.execScript || function( data ) { win[ "eval" ].call( win, data ); } )( data );
          // first try eval and if it fails try execscript, this is required for supporting IE9
          evalScriptData(script)
        }
        callbackFn()
      }
    } catch (e) {
      // the word EvaluationError is excluded in Sentry.
      // Do not change it!
      if (e instanceof Error) {
        e.message = `EvaluationError: ${e.message}`
      }
      logger.warn("Error evaluating script", e)
      throw toError(e)
    }
  }

  /**
   * Performs a post request which matches the specifications simple requests format to avoid preflight requests.
   * For this reason content-type is fixed to text/plain.
   * @param url request url
   * @param data request body
   * @return {Promise}
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
   */
  function xdr(url: string, data: EventRequestMessageV1) {
    const handlerResult = handler(url, data)
    return axios({
      url: handlerResult.url,
      data,
      method: handlerResult.method,
      headers: { "Content-Type": "text/plain" }
    })
  }

  return {
    loadScript,
    loadOnce,
    evalScript,
    xdr
  }
}

export type Loaders = ReturnType<typeof createLoader>
