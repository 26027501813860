// exported for testing, to test fallback functionality
export function clone<T>(obj: T): T
export function clone(obj: unknown): unknown {
  if (obj instanceof Date) {
    return new Date(obj.getTime())
  } else if (obj instanceof RegExp) {
    return new RegExp(obj.source, obj.flags)
  } else if (Array.isArray(obj)) {
    return obj.map(clone)
  } else if (obj && typeof obj === "object") {
    const result: Record<string, unknown> = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // @ts-expect-error any usage
        result[key] = obj[key] !== obj ? clone(obj[key]) : result
      }
    }
    return result
  }
  return obj
}

export const deepClone = window.structuredClone ?? clone
