import settings from "@/core/settings"
import filterContext from "./filterContext"
import { FilterRule } from "@/types"

function isSubcategoryOf(child: string, parent: string) {
  if (typeof child !== "string" || typeof parent !== "string") {
    return false
  }
  child = child.toLowerCase()
  parent = parent.toLowerCase()
  if (child === parent) {
    return true
  }
  if (child.indexOf(parent) === 0) {
    const sep = child.charAt(parent.length)
    if (sep === "/" || sep === "|") {
      return true
    }
  }
  return false
}

type Mapper = (s: string) => string

function collectionsIntersect(col1: string[], col2: string[], equalityMapper: Mapper = a => a) {
  return col1 && col2
    ? col1.some(c1 =>
        col2.some(
          c2 =>
            typeof c1 === "string" &&
            typeof c2 === "string" &&
            equalityMapper(c1.toLowerCase()) === equalityMapper(c2.toLowerCase())
        )
      )
    : false
}

function trimUrl(url: string) {
  if (!url) return url
  if (url[url.length - 1] !== "/") return url
  return url.substring(0, url.length - 1)
}

function containsUrl(col: string[], url: string) {
  if (!url) {
    return false
  }
  const urlsToMatch = [url]
  if (url.indexOf("?") > -1) {
    const baseUrl = url.substring(0, url.indexOf("?"))
    urlsToMatch.push(baseUrl)
  }
  return collectionsIntersect(col, urlsToMatch, trimUrl)
}

const opsOverride = Object.freeze({
  INCLUDES: {
    categories(patterns: string[], values: string[]) {
      return patterns && values
        ? patterns.some(patternCategory => values.some(category => isSubcategoryOf(category, patternCategory)))
        : false
    },
    urls: containsUrl,
    referer_urls: containsUrl
  },
  IS: {
    url: containsUrl
  }
})

type Check = (pattern: string, value: string) => boolean

function arrayCheck(check: Check) {
  return (pattern: string, value: string | string[]) =>
    Array.isArray(value) ? value.some(v => check(pattern, v)) : check(pattern, value)
}

const ops = Object.freeze({
  // comparison
  INCLUDES: collectionsIntersect,
  IS(patterns: string[], value: string) {
    return patterns.includes(value)
  },
  CONTAINS(patterns: string[], value: string) {
    return patterns.some(pattern => value.toLowerCase().includes(pattern.toLowerCase()))
  },
  MATCHES_REGEXP_PATTERN(patterns: string[], url: string) {
    return patterns.some(pattern => new RegExp(pattern.substring(1, pattern.length - 1)).test(url))
  },
  LT: arrayCheck(([pattern], value) => value < pattern),
  LTE: arrayCheck(([pattern], value) => value <= pattern),
  GT: arrayCheck(([pattern], value) => value > pattern),
  GTE: arrayCheck(([pattern], value) => value >= pattern),
  BETWEEN: arrayCheck(([min, max], value) => min <= value && value <= max),

  // recursive
  AND(filters: FilterRule[]) {
    return filters.reduce((match, filter) => match && applyFilter(filter), true)
  },
  OR(filters: FilterRule[]) {
    return filters.length === 0 ? true : filters.reduce((match, filter) => match || applyFilter(filter), false)
  }
})

const productFields: Record<string, boolean> = Object.freeze({
  categories: true,
  brands: true,
  tag1: true,
  tag2: true,
  tag3: true,
  tags: true,
  price: true,
  list_price: true,
  availability: true,
  discounted: true
})

function applyFilter(filter: FilterRule) {
  const { field, operator } = filter
  if (field && !(field in filterContext)) {
    throw new Error(`filter context lacks ${field} field`)
  }
  if (settings.serverProductPlacementFiltering && productFields[field!]) {
    // skip filtering by product attributes as they are applied on the server side
    return true
  }

  // @ts-expect-error improve types for filterContext
  const value = field && filterContext[field]()
  // @ts-expect-error improve types for opsOverride
  const operatorFunction = (opsOverride[operator] && opsOverride[operator][field]) || ops[operator]
  const matchResult = operatorFunction(filter.values, value)

  return filter.negate ? !matchResult : matchResult
}

export default function evaluateFilters(filters: FilterRule[]) {
  // by default filters are matched with AND
  return ops.AND(filters || [])
}
