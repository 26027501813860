import { PropsWithModal } from "../types"
import context from "@/core/context"
import { toggleElementVisibility } from "../utils/dom"
import { readPopupAttributes } from "../popupAttributes"
import { reportPopupAnalytics } from "../analytics"
import { copyToClipboard } from "@/utils/clipboard"

/**
 * Shows pop-up dialog
 */
export function showDialog({ popupId, campaignId, preview, dialog, modal }: PropsWithModal) {
  const siteWindow = context.site.window

  if (!popupId) return

  const couponCode = readPopupAttributes(popupId).coupon
  const nostoRibbon = dialog.querySelector<HTMLElement>("#NostoRibbon")
  if (nostoRibbon) {
    nostoRibbon.style.display = "none"
  }
  /**
   * If couponCode exists hide button for fetching it
   */
  if (couponCode) {
    const nostoCoupon = dialog.querySelector<HTMLElement>("#nostoCoupon")
    if (nostoCoupon) nostoCoupon.textContent = couponCode

    const couponElements = [
      { selector: "#nostoCouponGetBtn", value: "none" },
      { selector: "#nostoOverlaySend", value: "none" },
      { selector: "#nostoAddress", value: "none" },
      { selector: "#nostoCoupon", value: "" }
    ]
    toggleElementVisibility(dialog, couponElements)

    const nostoContinueBtn = dialog.querySelector<HTMLElement>("#nostoContinueBtn")
    const nostoCouponCopyBtn = dialog.querySelector<HTMLElement>("#nostoCouponCopyBtn")
    if (!nostoCouponCopyBtn) {
      if (nostoContinueBtn) {
        nostoContinueBtn.style.display = ""
      }
      modal.show()
    } else {
      if (nostoContinueBtn) {
        nostoContinueBtn.style.display = "none"
      }
      if (nostoCouponCopyBtn) {
        nostoCouponCopyBtn.style.display = ""
      }

      const copySuccess = () => {
        if (nostoContinueBtn) {
          if (nostoContinueBtn) {
            nostoContinueBtn.style.display = ""
          }
          if (nostoCouponCopyBtn) {
            nostoCouponCopyBtn.style.display = "none"
          }
        }
        const copyAlertContent = dialog.querySelector(".NostoOverlayCopyAlertContent")
        if (copyAlertContent) {
          copyAlertContent.classList.add("shown")
          setTimeout(() => {
            copyAlertContent.classList.remove("shown")
          }, 3000)
        }
        if (campaignId && !preview) {
          reportPopupAnalytics(campaignId, "couponCopied")
        }
      }

      const copyError = () => {
        // Copy-to-clipboard failed through JS. Try to select the coupon code in JS and prompt the use to copy it.
        let input = dialog.querySelector<HTMLInputElement>("#nostoCouponInput")
        if (!input) {
          return
        }
        const inputContainer = siteWindow.document.createElement("div")
        inputContainer.style.position = "relative"

        // Create a text input that will resemble the existing coupon code element.
        input = siteWindow.document.createElement("input")

        // Change the text in the success message from "Coupon was copied" to "Please copy the coupon"
        dialog
          .querySelectorAll<HTMLParagraphElement>(".NostoOverlayCopyAlertContent p")
          .forEach(p => (p.style.display = "none"))
        const nostoSelectPrompt = dialog.querySelector<HTMLElement>(".NostoOverlayCopyAlertContent p#nostoSelectPrompt")
        if (nostoSelectPrompt) {
          nostoSelectPrompt.style.display = ""
        }
        input.setAttribute("id", "nostoCouponInput")

        // Use a text input field because that is selectable through JS
        input.setAttribute("type", "text")
        input.setAttribute("size", String(couponCode.length + 1))
        input.value = couponCode
        // Hide the normal input field outline & borders
        input.style.webkitAppearance = "none"
        // @ts-expect-error Legacy code
        input.style.mozAppearance = "none"
        input.style.appearance = "none"
        input.style.border = "0"
        input.style.outline = "none"
        input.style.position = "relative"
        input.classList.add("NostoInputText")

        // Add a function to the store's window to select the contents of the input element
        const elem = input
        // @ts-expect-error Legacy code
        siteWindow.nostoSelectCoupon = () => {
          // Enable the text field for the while its contents are selected
          elem.disabled = false
          elem.selectionStart = 0
          elem.selectionEnd = elem.value.length
          elem.disabled = true
        }

        // Create a div to put on top of the disabled input field to capture mouse / touch events
        const inputEventTarget = siteWindow.document.createElement("div")
        inputEventTarget.style.position = "absolute"
        inputEventTarget.style.left = "0"
        inputEventTarget.style.right = "0"
        inputEventTarget.style.top = "0"
        inputEventTarget.style.bottom = "0"

        // The events are handled as directly as possible so that "copy/cut/..." dialog will be launched
        // on iOS after the content has been selected.
        inputEventTarget.setAttribute("ontouchend", "nostoSelectCoupon()")
        inputEventTarget.setAttribute("onclick", "nostoSelectCoupon()")
        inputEventTarget.setAttribute("onmouseup", "return false;")

        dialog.querySelector("#nostoCoupon")?.replaceWith(inputContainer)
        inputContainer.appendChild(input)
        inputContainer.appendChild(inputEventTarget)

        if (nostoContinueBtn) {
          nostoContinueBtn.style.display = ""
        }
        if (nostoCouponCopyBtn) {
          nostoCouponCopyBtn.style.display = "none"
        }
        const copyAlertContent = dialog.querySelector(".NostoOverlayCopyAlertContent")
        if (copyAlertContent) {
          copyAlertContent.classList.add("shown")
          setTimeout(() => {
            copyAlertContent.classList.remove("shown")
          }, 3000)
        }

        // Select the text initially
        input.setSelectionRange(0, input.value.length)
        // And then disable the input field and set its opacity to be normal to not resemble disabled field as much.
        input.disabled = true
        input.style.opacity = "1.0"

        if (campaignId && !preview) {
          reportPopupAnalytics(campaignId, "couponCopied")
        }
      }

      copyToClipboard(nostoCouponCopyBtn, () => dialog.querySelector("#nostoCoupon")!)
        // eslint-disable-next-line
        .then(copySuccess)
        // eslint-disable-next-line
        .catch(copyError)

      modal.show()
    }
  } else {
    const couponElements = [
      { selector: "#nostoCoupon", value: "none" },
      { selector: "#nostoCouponCopyBtn", value: "none" },
      { selector: "#nostoContinueBtn", value: "none" },
      { selector: "#nostoOverlaySend", value: "" },
      { selector: "#nostoCouponGetBtn", value: "" }
    ]
    toggleElementVisibility(dialog, couponElements)
    modal.show()
  }
}
