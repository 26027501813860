export function toObjectKeys<T extends string>(array: T[] | undefined): Record<T, true> {
  const object = Object.create(null)
  ;(array || []).forEach(key => {
    object[key] = true
  })
  return object
}

export function watchProperties<T extends object>(
  object: T,
  listener: <K extends keyof T>(key: K, newV: T[K], olv: T[K]) => void
) {
  // @ts-expect-error unsafe assignment
  const res: T = {}
  Object.keys(object).forEach((key: keyof T) => {
    Object.defineProperty(res, key, {
      set(v) {
        const old = object[key]
        object[key] = v
        listener(key, v, old)
      },
      get() {
        return object[key]
      },
      enumerable: true
    })
  })
  return res
}

export function filterObjectKeys(object: Record<string, unknown>, predicate: (field: string) => unknown) {
  return Object.keys(object)
    .filter(predicate)
    .reduce(
      (result, key) => ({
        ...result,
        [key]: object[key]
      }),
      {}
    )
}
